import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const HomePageContainer = styled.div`
  padding: 25px 0;
  background-color: ${(props) =>
    props.color === 'light' ? `${theme.SECONDARYTEXTCOLOR}` : `${theme.PRIMARYTEXTCOLOR}`};
  color: ${(props) => (props.color === 'light' ? props.theme.secondaryColor : props.theme.primaryColor)};
  margin-top: 120px;
  @media screen and (max-width: 450px) {
    padding: 20px 0;
    margin-top: 160px;
  }
`
export const DashboardWrapper = styled.div`
  padding: 10px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  justify-content: center;
  gap: 20px;
  color: ${({ theme }) => theme.primaryColor};

  @media screen and (min-width: 1960px) {
    grid-template-columns: repeat(auto-fit, minmax(700px, 0fr));
  }
  @media screen and (max-width: 1060px) {
    grid-template-columns: auto auto;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const NewDashboardWrapper = styled.div`
  padding: 0 40px;
  margin-top: 30px;
  width: 100%;
  display: grid;
  justify-content: center;
  /* grid-template-columns: auto auto; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  gap: 20px;
  color: ${({ theme }) => theme.primaryColor};
  /* font-family: ${({ theme }) => theme.fontFamily}; */
  @media screen and (min-width: 1960px) {
    grid-template-columns: repeat(auto-fit, minmax(500px, 0fr));
  }
  @media screen and (max-width: 1060px) {
    grid-template-columns: auto auto auto;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const FirstSection = styled.div`
  cursor: pointer;
  position: relative;
  img {
    height: -webkit-fill-available;
    width: 100%;
    cursor: pointer;
  }
  @media screen and (max-width: 1060px) {
    max-width: 100%;
  }
`
export const InsideContent = styled.div`
  position: absolute;
  top: 20%;
  left: 10%;
  color: ${({ theme }) => theme.primaryColor};
  span {
    font-size: 20px;
    font-weight: 400;
  }
`
export const FashionText = styled.div`
  font-size: 30px;
  font-weight: 500;
  @media screen and (max-width: 1060px) {
    font-size: 26px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`
export const ShopNowButton = styled.button`
  border: none;
  background-color: ${theme.SECONDARYTEXTCOLOR};
  width: 100%;
  max-width: 115px;
  border-radius: 8px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-size: 12px;
  margin-top: 10px;
  color: ${({ theme }) => theme.secondaryColor};
  /* font-family: ${({ theme }) => theme.fontFamily}; */
`
export const SecondSection = styled.div`
  img {
    height: -webkit-fill-available;
    width: 100%;
    cursor: pointer;
  }
`
export const ThirdSection = styled.div`
  img {
    height: -webkit-fill-available;
    width: 100%;
    cursor: pointer;
  }
`
export const RightFirstSection = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto;

  img {
    height: -webkit-fill-available;
    width: 100%;
  }
`
export const GrocerySection = styled.div`
  position: relative;
`
export const GroceryContent = styled.div`
  position: absolute;
  top: 10%;
  left: 8%;
  width: 100%;
  max-width: 151px;
`
export const ElectronicContent = styled.div`
  position: absolute;
  top: 20%;
  left: 5%;
  width: 100%;
`
export const RightSecondSection = styled.div`
  position: relative;
  img {
    height: -webkit-fill-available;
    width: 100%;
  }
`
export const MicSection = styled.div`
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
  transition: all ease-in-out;
`
export const MicGif = styled.div`
  img {
    max-width: 150px;
  }
`
export const SearchMicComponent = styled.div``
export const GridListViewWrapper = styled.div`
  display: flex;
  padding: 26px 50px;
  background: #f6f6f6;
  width: 100%;
  justify-content: space-between;

  button {
    background-color: ${theme.PRIMARYCOLOR} !important;
  }

  svg {
    path {
      stroke: ${theme.SECONDARYTEXTCOLOR} !important;
    }
  }
  @media (max-width: 767px) {
    margin: 10px 26px;
    padding: 0;
    .breadcrumb-wrapper {
      padding-top: 0 !important;
    }
  }
`

export const MobileDashboardWrapper = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    display: block;
  }
  .slick-track {
    display: flex;
    gap: 5px;
  }
`
export const NewMobileDashboardWrapper = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    display: block;
    margin: 30px auto;
  }
  .slick-track {
    display: flex;
    gap: 5px;
  }
`
export const BackgroundImageSection = styled.div`
  max-width: 40%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  img {
    height: -webkit-fill-available;
    width: 100%;
    max-height: 400px;
  }
  @media screen and (max-width: 1060px) {
    max-width: 100%;
    img {
      max-height: fit-content;
    }
  }
`
export const TitleWrapper = styled.div`
  padding: 0 40px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const TitleText = styled.div`
  margin: 0 22px;
  margin-bottom: 14px;
  font-size: 30px;
  font-weight: 600;
  text-transform: capitalize;
  color: ${theme.SECONDARYTEXTCOLOR};
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
export const TitleText1 = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  color: #1d1d1d;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
export const View = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYCOLOR};
  cursor: pointer;
  text-transform: capitalize;
`
export const InterestedContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  justify-content: center;
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const InterestedMaxContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  justify-content: center;
  margin: 20px auto;
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const InterestedContent = styled.div`
  overflow: hidden;
  cursor: pointer;
  position: relative;

  img {
    height: -webkit-fill-available;
    width: 100%;
    max-height: 400px;
  }

  @media screen and (max-width: 1060px) {
    max-width: 100%;
  }
`

export const ProductsContainer = styled.div`
  margin: 20px 10px;

  .slick-prev:before {
    display: none;
  }
  .slick-next:before {
    display: none;
  }
  .slick-prev {
    color: ${theme.SECONDARYTEXTCOLOR};
    top: 110px !important;
  }
  .slick-next {
    color: ${theme.SECONDARYTEXTCOLOR};
    top: 110px !important;
  }
  .slick-list {
    margin-left: 20px;
  }
  .slick-track {
    margin: 0 !important;
  }
  @media (max-width: 767px) {
    margin: 10px 0;
    .slick-list {
      margin-left: 0;
    }
    .slick-prev {
      display: none;
    }
    .slick-next {
      display: none;
    }
  }

  @media screen and (max-width: 450px) {
    display: block;
  }

  .slick-initialized .slick-slide {
    @media screen and (max-width: 450px) {
      max-width: 210px;
    }
    display: block !important;
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
  }
`
export const ProductCard = styled.div`
  width: 100%;
  max-width: 200px;
  height: 200px;
  padding: 11px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border-radius: 12px;
  cursor: pointer;
  .slick-slide {
    margin: 10px;
  }
`
export const ProductsWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  gap: 16px;
  max-width: 200px;
  width: 100%;
  @media (max-width: 1600px) {
    gap: 9px;
  }
`
export const ProductDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ProductDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ProductName = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  width: 120px;
  color: ${theme.TERTIARYTEXTCOLOR};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ProductPrice = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.PRIMARYCOLOR};
`
export const TopProductsWrapper = styled.div`
  padding: 0 40px;
  margin-top: 30px;
  @media (max-width: 450px) {
    padding: 0 20px;
    margin-top: 20px;
  }
`
export const TopProductsTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  button {
    text-transform: none;
    border-radius: 8px;
    padding: 3px;
    font-size: 12px;
    width: 90px;
  }
`
export const IconButton = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;

  svg > path {
    stroke: ${theme.PRIMARYCOLOR};
    width: 20px;
    height: 20px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`

export const TopSellingWishlistWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`
export const TopSellingImageSection = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 130px;
    min-height: 140px;
    max-height: 140px;
    margin: 10px auto 0 auto;
    object-fit: contain;
  }
  @media (max-width: 450px) {
    img {
      margin: 0 auto;
    }
  }
`
export const TopSellingOptionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 28px;
  button {
    background-color: ${theme.PRIMARYCOLOR};
    font-weight: 500;
    max-width: 84px;
    width: 100%;
    padding: 2px 12px;
    font-size: 12px;
  }
`

export const BannerWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  /* height: calc(100vh - 140px); */
  .slick-prev:before {
    display: none;
  }
  .slick-next:before {
    display: none;
  }

  @media (max-width: 767px) {
    margin: 10px 0;
    .slick-list {
      margin-left: 0;
    }
    .slick-prev {
      display: none;
    }
    .slick-next {
      display: none;
    }
  }

  @media screen and (max-width: 450px) {
    display: block;
  }

  .slick-prev {
    left: 10px; /* Adjust as needed */
    z-index: 1;
  }

  .slick-next {
    right: 10px; /* Adjust as needed */
    z-index: 1;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 24px; /* Adjust size as needed */
    color: black; /* Change color if necessary */
  }

  @media screen and (max-width: 450px) {
    display: none;
  }
`

export const BannerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 500px) {
    img {
      height: unset;
      object-fit: contain;
    }
  }
`

export const OfferBannerWrapper = styled.div`
  width: 100%;
  z-index: 1;
  img {
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  cursor: pointer;
  @media (max-width: 500px) {
    display: none;
  }
`
export const MobileOfferBannerWrapper = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 500px) {
    display: block;
    img {
      width: 100%;
      height: auto;
    }
  }
`
