import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const NavbarWrapper = styled.div`
  border-bottom: 1px solid ${theme.BORDERCOLORLIGHT};
  color: ${theme.SECONDARYTEXTCOLOR};
  @media (max-width: 450px) {
    border-bottom: none;
  }
`
export const NavbarContentContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`
export const NavbarContent = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px;
  justify-content: space-between;
  background: #fff;
  .theme_logo {
    width: 85px;
    height: 75px;
    border-radius: 10px;
    cursor: pointer;
  }
  .logo {
    width: 200px;
    height: 45px;
    cursor: pointer;
  }
  @media (max-width: 450px) {
    padding: 16px;
  }
`
export const AddressContainer = styled.div`
  background: ${theme.PRIMARYCOLOR};
  padding: 10px 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const FreeText = styled.div`
  font-size: 14px;
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const PincodeText = styled.div`
  font-size: 14px;
  color: ${theme.SECONDARYTEXTCOLOR};
  @media (max-width: 450px) {
    font-size: 12px;
    font-weight: 400;
    color: ${theme.SECONDARYTEXTCOLOR};
    cursor: pointer;
  }
`
export const RightNavSection = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
`
export const GoogleTranslateElement = styled.div`
  position: relative;
  align-self: center;
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 150px;
  max-width: 150px;
  margin-right: 10px;
  .goog-te-gadget .goog-te-combo {
    background: ${theme.PRIMARYCOLOR};
  }
  svg {
    visibility: hidden;
  }
  @media (max-width: 450px) {
    min-width: 150px;
    max-width: 150px;
    svg {
      display: none;
    }
    .goog-te-gadget .goog-te-combo {
      background: transparent;
      // border: none;
      // color: ${theme.PRIMARYTEXTCOLOR};
      // font-size: 15px;
      // font-weight: 400;
    }
  }
`
export const MobileLocationWrapper = styled.div`
  display: none;
  @media (max-width: 450px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: ${theme.PRIMARYCOLOR};
    padding: 2px;
  }
`
export const MobileFreeText = styled.p`
  font-size: 12px;
  margin: 0 8px;
`
export const DeliverAddressWrapper = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  @media (max-width: 450px) {
    padding: 10px;

    // path {
    //   fill: ${theme.PRIMARYTEXTCOLOR};
    // }
    svg {
      width: 14px;
      height: 14px;
    }
  }
`
export const MainTitle = styled.div`
  font-size: 34px;
  font-weight: 600;
  color: ${(props) => (props.color === 'light' ? `${theme.PRIMARYCOLOR}` : `${theme.SECONDARYTEXTCOLOR}`)};
  cursor: pointer;
`
export const FavouriteWrapper = styled.div`
  display: flex;
  max-width: 44px;
  padding: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: 8px;
  height: 44px;
  cursor: pointer;

  .bage-count {
    span {
      top: -7px !important;
      right: -2px !important;
    }
  }

  @media screen and (max-width: 1000px) {
    padding: 5px;
  }
`
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const ItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 156px;
  button {
    border-radius: 10px;
  }

  @media (max-width: 767px) {
    button {
      color: ${theme.SECONDARYTEXTCOLOR};
      text-transform: none;
      border: 1px solid ${theme.SECONDARYTEXTCOLOR};
      border-radius: 10px;
      padding: 0;
      &:hover {
        border: 1px solid ${theme.SECONDARYTEXTCOLOR};
      }
    }
  }
`
export const ButtonContainer = styled.div`
  button {
    text-transform: none;
    min-height: 44px;
    width: max-content;
  }
`
export const LogoWrapper = styled.div`
  svg {
    cursor: pointer;
  }
`
export const BoxWrapper = styled.div`
  min-height: 70vh;
  .css-hyum1k-MuiToolbar-root {
    min-height: 0;
  }
`

export const SearchWrapper = styled.div`
  width: 100%;
  max-width: 534px;

  @media screen and (max-width: 1000px) {
    max-width: 400px;
  }
`

export const SearchContent = styled.form`
  padding: 2px 10px !important;
  display: flex;
  align-items: center;
  border-radius: 12px;
  min-height: 40px;
  border: 1px solid ${theme.TERTIARYGREYCOLOR};
`
export const ThemeToggleWrapper = styled.button`
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${theme.SECONDARYTEXTCOLOR};
  width: max-content;
`

export const InnerWrapper = styled.div`
  background: ${theme.PRIMARYCOLOR};
  border-radius: 50px;
  padding: 0 5px;
`
export const ToggleSection = styled.div`
  display: flex;
  svg {
    path {
      fill: ${theme.PRIMARYCOLOR};
    }
  }
`
export const InnerToggleWrapper = styled.div`
  background: ${theme.PRIMARYCOLOR};
  border-radius: 50px;
  padding: 0 5px;

  svg {
    path {
      fill: ${theme.SECONDARYTEXTCOLOR};
    }
  }
`
export const PinCodeContainer = styled.div`
  display: flex;
  padding: 20px 20px 0 20px;
  justify-content: flex-start;
  /* width: 100%; */
  gap: 20px;
  width: 100%;
  input {
    width: 300px;
    @media screen and (max-width: 650px) {
      width: 150px;
    }
  }
`
export const ButtonWrapper = styled.div`
  input {
    padding: 12px 14px;
  }
  button {
    padding: 10px 15px;
    border: 1px solid ${theme.PRIMARYCOLOR};
    color: ${theme.PRIMARYCOLOR};
    min-height: 56px;
    :hover {
      background-color: ${theme.PRIMARYCOLOR};
      color: ${theme.SECONDARYTEXTCOLOR};
    }
  }
`
export const MobileNavContainer = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${theme.SECONDARYTEXTCOLOR};
    padding: 16px 16px;
    gap: 10px;
  }
`
export const LogoImage = styled.div`
  img {
    width: 158px;
  }
`
export const MenuSection = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  svg {
    cursor: pointer;
  }
`
export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  background: ${theme.PRIMARYCOLOR};
  min-height: 100%;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  display: grid;
  align-content: flex-start;
  gap: 20px;
  padding: 20px;
`
export const UserName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.SECONDARYTEXTCOLOR};
`
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  a {
    color: ${theme.SECONDARYTEXTCOLOR};
    text-decoration: none;
  }
`
export const ListItems = styled.div`
  display: flex;
  gap: 10px;
`
export const IconsWrapper = styled.div`
  width: 30px;
  height: 30px;
`
export const ListOptions = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.SECONDARYTEXTCOLOR};
`
export const WebWrapper = styled.div`
  display: block;
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const ImageWrapper = styled.div`
  width: 68px;
  height: 68px;
  img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }
`
export const MobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    display: flex;
    gap: 10px;
    /* gap: 28px; */
  }
  svg {
    stroke: ${theme.PRIMARYCOLOR};
  }
  path {
    stroke: ${theme.PRIMARYCOLOR};
  }
  circle {
    stroke: ${theme.PRIMARYCOLOR};
  }
  .bage-count {
    span {
      font-weight: 500;
      font-size: 11px;
      line-height: 1;
      padding: 0 6px;
      border-radius: 50% !important;
      top: 2px;
    }
  }
`
export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`
export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 210px);
`
export const MenuItemSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
`
export const WislishtWrapper = styled.div`
  cursor: pointer;
`
