import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const ProductsContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.secondaryColor};
`
export const HeadSection = styled.div`
  width: 100%;
  padding: 20px 50px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  margin-top: 140px;
  .text-color {
    color: ${theme.PRIMARYCOLOR};
  }
  .title-color {
    color: ${theme.TEXTCOLOR};
  }
  .label-color {
    color: ${theme.PRIMARYTEXTCOLORDARK};
  }
  span {
    color: ${theme.PRIMARYCOLOR};
  }
  @media (max-width: 450px) {
    padding: 8px 22px;
    margin-top: 180px;
  }
`
export const ContentSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  margin: 30px 20px;
  @media screen and (max-width: 450px) {
    /* margin: 20px; */
    margin: 10px 0 0 0;
    flex-wrap: wrap;
  }
`
export const FilterSection = styled.div`
  width: 100%;
  max-width: 250px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 20px;
  border-radius: 16px;
  .MuiFormControlLabel-root {
    width: 100%;
    gap: 10px;
  }
  .MuiCheckbox-root {
    border: none;
  }
  /* @media screen and (max-width: 450px) {
    display: none;
  } */
`
export const RadioSection = styled.div`
  .radio-group {
    padding: 6px !important;
  }
`
export const FilterMobile = styled.div`
  margin: 30px;
  cursor: pointer;
  width: max-content;
  display: flex;
  gap: 10px;
  @media (max-width: 767px) {
    margin: 0;
  }
`
export const Title = styled.div`
  font-size: 16px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const ProductSection = styled.div`
  width: 100%;
`
export const SortSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
`
export const ActiveFilters = styled.div`
  width: 100%;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
  path {
    stroke: ${theme.PRIMARYCOLOR};
  }
  @media screen and (max-width: 450px) {
    gap: 10px;
    flex-wrap: wrap;
  }
`

export const Sort = styled.div`
  font-size: 14px;
`
export const CardSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 0fr));
  margin-top: 20px;
  gap: 20px;
  border-radius: 12px;

  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr 1fr;
    gap: 0;
    button {
      margin-top: 8px;
      padding: 6px 7px;
      font-size: 12px;
    }
  }
`
export const EmptyWrapper = styled.div`
  height: calc(100vh - 450px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1600px) {
    height: calc(100vh - 392px);
  }
  @media (max-width: 1500px) {
    height: calc(100vh - 200px);
  }
`

export const ProductCard = styled.div`
  padding: 10px;
  &:hover {
    box-shadow:
      0 4px 8px 0 ${theme.CARDCOLOR},
      0 6px 20px 0 ${theme.CARDCOLORLIGHT};
  }

  @media (max-width: 450px) {
    // max-width: 166px;
    min-width: 155px;
    width: 100%;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  // background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 15px;
  width: 100%;
  max-width: 250px;
  // border-radius: 10px;
  min-height: 250px;
  max-height: 250px;
  position: relative;
  cursor: pointer;

  @media (max-width: 450px) {
    min-height: 160px;
    max-height: 206px;
    padding: 10px;
  }
`
export const CardWrapper = styled.div`
  border: 1px solid #e4e7e9;
  padding: 15px;
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 200px;
  /* margin-right: 30px; */
  cursor: pointer;
`
export const CardContainer = styled.div`
  border: 1px solid #e4e7e9;
  padding: 15px;
  width: 100%;
  max-width: 200px;
  min-width: 200px;
  border-radius: 10px;
  min-height: 200px;
  max-height: 200px;
  cursor: pointer;
`
export const SubCategoryText = styled.h2`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  text-align: center;
`
export const DescriptionWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
export const OutOfStockItem = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #e62525;
  cursor: none;
`
export const Description = styled.div`
  font-size: 14px;
  max-width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-weight: 500;
  color: ${theme.PRIMARYTEXTCOLOR};
  @media (max-width: 450px) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
`
export const Price = styled.div`
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    color: ${theme.PRIMARYCOLOR};
    font-size: 12px;
  }
`
export const OriginalPrice = styled.div`
  display: flex;
  gap: 10px;
  font-size: 18px;
  font-weight: 400;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  text-decoration: line-through;
  @media (max-width: 450px) {
    font-size: 12px;
  }
`
export const Discount = styled(OriginalPrice)`
  text-decoration: none;
`

export const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  img {
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
    margin: 10px auto 0 auto;
  }
  @media (max-width: 450px) {
    img {
      max-width: 85px;
      min-height: 84px;
      max-height: 84px;
      margin: 0 auto;
    }
  }
`

export const CartItemMainContainer = styled.div`
  padding: 35px 60px;
  @media screen and (max-width: 450px) {
    padding: 16px;
  }
`
export const CartItemContainer = styled.div`
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  width: 100%;
  border-radius: 8px;
`
export const MobileFilter = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    display: block;
  }
`
export const WebFilter = styled.div`
  display: block;
  width: 100%;
  max-width: 300px;
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const CardSectionWrapper = styled.div``
export const CartTableItemsWrapper = styled.div`
  border-top: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  background-color: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 10px 20px;
  .price {
    min-width: 180px !important;
    max-width: 180px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const PriceWrapper = styled.div`
  min-width: 180px;
  max-width: 180px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    background: ${theme.PRIMARYCOLOR};
    min-height: 40px;
    text-transform: none !important;
  }
`
export const CartSummary = styled.div`
  padding: 20px;
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  @media (max-width: 450px) {
    max-width: 350px;
    min-width: 327px;
    border-radius: 10px;
    padding: 16px;
  }
`
export const WebCartWrapper = styled.div`
  display: block;
  @media screen and (max-width: 450px) {
    display: none;
  }
`
export const MobileCartWrapper = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    /* display: grid;
    grid-template-columns: auto auto; */
    display: flex;
    gap: 10px;
    border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  }
`
export const MobileContent = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    display: grid;
    padding: 10px;
  }
`
export const CartItemsDetails = styled.div`
  width: 100%;
  padding: 20px;
  @media (max-width: 450px) {
    padding: 0 16px 16px 16px;
  }
`
export const ItemDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
`
export const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  button {
    background-color: ${theme.PRIMARYCOLOR};
    font-weight: 500;
    max-width: 84px;
    width: 100%;
    padding: 2px 12px;
    font-size: 12px;
  }
`
export const OutOfStockWrapper = styled.div`
  cursor: none;
`
export const WishlistWrapper = styled.div`
  position: absolute;
  right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`

export const OrderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 12px;
  button {
    text-transform: none;
    border-radius: 8px;
    padding: 6px;
    font-size: 12px;
    max-width: 90px;
    width: 100%;
    :disabled {
      pointer-events: none; /* Prevent clicks */
      opacity: 0.5;
    }
  }

  @media (max-width: 450px) {
    gap: 5px;
    button {
      max-width: 79px;
      font-size: 10px;
    }
  }
`
export const ActiveFilterWrapper = styled.div`
  display: flex;
  font-size: 14px;
  gap: 10px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    gap: 5px;
  }
`
export const ActiveFilterText = styled.div`
  font-size: 14px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  @media (max-width: 450px) {
    gap: 5px;
  }
`
export const ProductCartWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`
export const AvailabilityWrapper = styled.div`
  color: ${theme.SECONDARYERRORCOLOR};
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`
export const ProductName = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const ProviderName = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const Wrapper = styled.div`
  img {
    height: 100%;
  }
`
export const CartItemTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  max-width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const CartItemPrice = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
  min-width: 60px;
`
export const ProviderTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const CartWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 15px;
`
export const CartDetailWrapper = styled.div`
  width: 100%;
`
export const ProductDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
`
export const ProductTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const QuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.8px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 4.8px;
  padding: 4px 8px;
  width: 80px;
`
export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`
