import styled from 'styled-components'
import FeedbackImage from 'assets/images/feedback.jpg'
import { theme } from 'styles/Theme/theme'

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
export const LeftContainer = styled.div`
  position: relative;
  width: 100%;
  // height: 100vh;
  background: url(${FeedbackImage}) no-repeat center center / cover;
`

export const Logo = styled.img`
  position: absolute;
  width: 146px;
  // height: 30px;
  top: 26px;
  left: 37px;
  gap: 0px;
  opacity: 0px;
`

export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 579px;
  // height: 696px;
  padding: 10px 20px;
  @media (max-height: 600px) {
    height: 540px;
    overflow: scroll;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`
export const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
`
export const Title = styled(Heading)`
  font-size: 14px;
  line-height: 22px;
  .asterik {
    color: ${theme.SECONDARYERRORCOLOR};
  }
`
export const SubTitle = styled(Title)`
  font-weight: 400;
`
export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
export const TextArea = styled.textarea`
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 8px;
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
`
export const HelpText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  > span {
    color: ${theme.PRIMARYCOLOR};
  }
`
