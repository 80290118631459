import { useState } from 'react'
import FilterContainer from '../../views/searchProducts/filterSection'
import { ProductCards } from '../../views/searchProducts/productCard'
import {
  ProductsContainer,
  ContentSection,
  ProductSection,
  ActiveFilters,
  WebFilter,
  MobileFilter,
  FilterMobile,
  ActiveFilterWrapper,
  ActiveFilterText,
} from '../../styles/searchProducts'
import { useTheme } from '../../context/newThemeContext'
import { Drawer } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import NoDataFound from 'views/EmptyData'
import Spinner from 'components/Loader'

const SearchProducts = ({ products, setPriceRange, priceRange, setProducts, isLoading }) => {
  const handlePriceRangeChange = (event) => {
    const selectedPriceRange = event.target.value
    setPriceRange(selectedPriceRange)
  }

  const { theme } = useTheme()

  const [open, setOpen] = useState(false)
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const DrawerList = (
    <div style={{ padding: '20px' }}>
      <FilterContainer onPriceRangeChange={handlePriceRangeChange} priceRange={priceRange} setOpen={setOpen} />
    </div>
  )

  return (
    <ProductsContainer theme={theme}>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <MobileFilter>
        <FilterMobile onClick={toggleDrawer(true)}>
          <TuneIcon /> Filters
        </FilterMobile>
      </MobileFilter>
      <ContentSection>
        <WebFilter>
          <FilterContainer onPriceRangeChange={handlePriceRangeChange} priceRange={priceRange} />
        </WebFilter>
        <ProductSection>
          <ActiveFilters>
            <ActiveFilterWrapper>Active Filter : {priceRange}</ActiveFilterWrapper>
            <ActiveFilterText>{`${products?.length} ${products?.length === 1 || products?.length === 0 ? 'result' : 'results'} found`}</ActiveFilterText>
          </ActiveFilters>
          {products?.length > 0 ? (
            <ProductCards products={products} setProducts={setProducts} />
          ) : (
            <NoDataFound content={'No Product Found'} />
          )}
          {isLoading && <Spinner />}
        </ProductSection>
      </ContentSection>
    </ProductsContainer>
  )
}

export default SearchProducts
