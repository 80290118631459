import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import ReactGA from 'react-ga4'
import CartIcon from '../../assets/svg/CartIcon'
import {
  MainContainer,
  ProductDetailsWrapper,
  OrderNo,
  // SubOrderDetails,
  ProductDetailsLeft,
  ProductDetailsRight,
  WishlistWrapper,
  ProductWrapper,
  CartButtonWrapper,
  ButtonWrapper,
  NoDataWrapper,
  WishlistTitle,
  PaginationWrapper,
} from '../../styles/myOrder/orderDetails'
import useGet from 'hooks/useGet'
import { deleteWithAuthentication, postLoginCall } from 'api/axios'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'
import { getOrCreateDeviceId, getUserId } from 'helper'
import CustomizationRenderer from 'components/application/product-list/product-details/CustomizationRenderer'
import { CartContext } from 'context/cartContext'
import ConfirmationModal from 'views/ConfirmationModal'
import ModalComponent from 'components/common/Modal'
import NoDataFound from 'views/EmptyData'
import MobileWishlist from 'views/Profile/MobileWishlist'
import ProductImage from 'assets/images/no_image_found.png'
import OutOfStock from 'components/outOfStock'
import { Pagination } from '@mui/material'

const WishlistDetails = () => {
  const history = useHistory()
  const dispatch = useContext(ToastContext)
  const [customization_state, setCustomizationState] = useState({})
  const [removeItem, setRemoveItem] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState('')
  const [deviceId, setDeviceId] = useState('')
  const userId = getUserId()
  const [totalOrdersCount, setTotalOrdersCount] = useState(0)
  const { fetchCartItems } = useContext(CartContext)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    postPerPage: 10,
  })

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Wishlist' })
  }, [])

  useEffect(() => {
    let id = ''
    const fetchId = async () => {
      id = await getOrCreateDeviceId()
      setDeviceId(id)
    }

    fetchId()
  }, [])

  const { refetch: fetchWishlist, data: wishlistData } = useGet(
    'wishlist',
    `/clientApis/v2/wishlist/${userId}/${deviceId}?page=${pagination.currentPage}&limit=${pagination.postPerPage}`,
  )

  useEffect(() => {
    if (wishlistData) {
      setTotalOrdersCount(wishlistData?.totalCount || 0)
    }
  }, [wishlistData])
  const handleClick = (productId) => {
    history.push(`/products?productId=${productId}`)
  }

  useEffect(() => {
    if (deviceId) fetchWishlist()
  }, [deviceId, pagination.currentPage])

  const handleDelete = async (id) => {
    setRemoveItem(true)
    setDeleteItemId(id)
  }

  let selectedCustomizationIds = []

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = (product) => {
    if (!product?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = product?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }

  const handleAddToCart = async (product) => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Add to wishlist',
    })

    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`

    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      customisations: getCustomizations(product),
      hasCustomisations: !!getCustomizations(product),
      customisationState: customization_state,
      local_id: product?.item?.local_id,
      id: product?.item?.id,
      provider: {
        id: product?.item?.provider?.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      const url = `/clientApis/v2/wishlist/${userId}/${product._id}`
      await deleteWithAuthentication(url)
      fetchWishlist()
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }

    try {
      const data = await postLoginCall(url, payload)
      if (data) {
        fetchCartItems()
        localStorage.setItem('cartItems', JSON.stringify(data))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to cart successfully.',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            'Unfortunately, We’re experiencing some technical issues while adding items to your cart. Please bear with us & get back to us sometime.',
        },
      })
    }
  }

  return (
    <>
      <WishlistWrapper>
        <WishlistTitle>Wishlist</WishlistTitle>
        {wishlistData?.wishlistData && wishlistData?.wishlistData?.length > 0 ? (
          wishlistData?.wishlistData?.map((item, index) => {
            return (
              <MainContainer key={index}>
                <ProductDetailsWrapper>
                  <ProductDetailsLeft onClick={() => handleClick(item?.item?.id)}>
                    <ProductWrapper>
                      {item?.item?.product?.quantity?.available?.count === '0' ? <OutOfStock /> : ''}
                      <img
                        className="product-image"
                        src={
                          item?.item?.product?.descriptor?.images[0]
                            ? item?.item?.product?.descriptor?.images[0]
                            : ProductImage
                        }
                        alt="Image"
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                      />
                    </ProductWrapper>
                    <div>
                      <OrderNo>{item?.item?.product?.descriptor?.name}</OrderNo>
                    </div>
                  </ProductDetailsLeft>
                  <OrderNo>₹{item?.item?.product?.price?.value}</OrderNo>
                  <ProductDetailsRight>
                    <ButtonWrapper>
                      <Button
                        variant="contained"
                        className="edit-button"
                        onClick={() => {
                          handleAddToCart(item)
                        }}
                        disabled={item?.item?.product?.quantity?.available?.count === '0'}
                      >
                        <CartButtonWrapper>
                          <CartIcon /> Add to cart
                        </CartButtonWrapper>
                      </Button>
                      <Button variant="outlined" onClick={() => handleDelete(item._id)}>
                        Remove
                      </Button>
                    </ButtonWrapper>
                  </ProductDetailsRight>
                </ProductDetailsWrapper>
              </MainContainer>
            )
          })
        ) : (
          <NoDataWrapper className="empty-state">
            <NoDataFound content={'No Product Found'} />
          </NoDataWrapper>
        )}

        <CustomizationRenderer
          customization_state={customization_state}
          setCustomizationState={setCustomizationState}
        />

        {removeItem && (
          <ModalComponent open={removeItem} onClose={() => setRemoveItem(false)}>
            <ConfirmationModal
              onClose={() => setRemoveItem(false)}
              title={'Are you sure want to remove this item from your wishlist ?'}
              type={'wishlist'}
              id={deleteItemId}
            />
          </ModalComponent>
        )}
      </WishlistWrapper>
      <MobileWishlist pagination={pagination} setTotalOrdersCount={setTotalOrdersCount} />
      <PaginationWrapper>
        <Pagination
          count={Math.ceil(totalOrdersCount / pagination.postPerPage)}
          shape="rounded"
          color="primary"
          page={pagination.currentPage}
          onChange={(event, currentPage) => {
            event
            setPagination((prevState) => ({ ...prevState, currentPage }))
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        />
      </PaginationWrapper>
    </>
  )
}

export default WishlistDetails
