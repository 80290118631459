import { makeStyles } from '@mui/styles'
import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

const useStyles = makeStyles({
  orderItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '33px',
    // border: `1px solid #EDEDED`,
    // borderRadius: '12px !important',
  },
  orderCard: {
    boxShadow: 'none !important',
    background: '#F5F5F5 !important',
    display: 'flex',
    minHeight: '250px !important',
    height: '300px !important',
    borderRadius: '12px 0px 0px 12px !important',
  },
  orderImage: {
    margin: 'auto !important',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  orderDetailsTypo: {
    padding: '37px 16px 26px 16px',
    position: 'relative',
  },
  deliveryTimeTypo: {
    marginLeft: '6px !important',
    color: '${theme.PRIMARYGREYCOLOR} !important',
    fontWeight: '500 !important',
    lineHeight: '18px !important',
  },
  deliveryTimeTypoValue: {
    fontWeight: '500 !important',
    lineHeight: '18px !important',
  },
  statusChip: {
    float: 'right',
    background: '${theme.PRIMARYCOLOR} !important',
  },
  addressTypo: {
    marginTop: '12px !important',
    fontWeight: '500 !important',
    color: '${theme.PRIMARYGREYCOLOR} !important',
    lineHeight: '18px !important',
  },
  itemNameTypo: {
    marginTop: '10px !important',
    fontWeight: '600 !important',
    lineHeight: '20px !important',
  },
  vegNonVegIcon: {
    marginRight: '5px !important',
    // marginLeft: '10px !important',
    height: '16px !important',
    width: '16px !important',
  },
  itemTypo: {
    marginRight: '16px !important',
  },
  priceTypo: {
    marginTop: '16px !important',
    fontSize: '18px !important',
  },
  priceTypoLabel: {
    marginTop: '12px !important',
    fontWeight: '500 !important',
    color: '${theme.PRIMARYGREYCOLOR} !important',
    lineHeight: '18px !important',
  },
  orderDateTime: {
    marginTop: '14px !important',
    fontWeight: '500 !important',
    color: '${theme.PRIMARYGREYCOLOR} !important',
    lineHeight: '18px !important',
  },
  viewSummaryButton: {
    float: 'right',
    color: '${theme.PRIMARYCOLOR} !important',
    border: '1px solid ${theme.PRIMARYCOLOR} !important',
  },
  trackOrderButton: {
    float: 'right',
    marginLeft: '16px !important',
  },
  downloadInvoiceButton: {
    float: 'right',
    marginLeft: '16px !important',
  },
  '@media (max-width: 600px)': {
    orderDetailsTypo: {
      padding: '13px 16px 26px 16px',
      position: 'relative',
    },
    addressTypo: {
      fontSize: '12px !important',
    },
    priceTypoLabel: {
      fontSize: '12px !important',
      marginTop: '5px !important',
    },
    priceTypo: {
      fontSize: '12px !important',
      marginTop: '5px !important',
    },
    orderDateTime: {
      fontSize: '12px !important',
      marginTop: '5px !important',
    },
    itemTypo: {
      fontSize: '12px !important',
    },
  },
})

export const ButtonWrapper = styled.div`
  padding-top: 20px;
  button {
    text-transform: none;
  }
`
/**** Complaints Page CSS ****/
export const ComplaintsMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  @media (max-width: 767px) {
    margin: 12px 16px 0 16px;
    gap: 18px;
    width: unset;
  }
`
export const ComplaintStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  svg {
    cursor: pointer;
  }
`
export const ComplaintsTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.TERTIARYCOLORDARK};
  @media (max-width: 767px) {
    font-size: 20px;
  }
`
export const ComplaintsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .empty-state {
    min-height: 62vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const ComplaintsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 24px;
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border-radius: 12px;
  @media (max-width: 1600px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    padding: 16px;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: 1600px) {
    justify-content: unset;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 12px;
  }
`
export const MobileDetailContainer = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    gap: 12px;
  }
`
export const MobileImageWrapper = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
    img {
      width: 60px;
    }
  }
`
export const MobileTitleWrapper = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    gap: 12px;
    .status-chip {
      background: ${theme.PRIMARYCOLOR};
      text-transform: uppercase;
      color: #fff;
    }
  }
`
export const ImageWrapper = styled.div`
  img {
    width: 60px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const ComplaintsDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
export const TitleWrapper = styled.div`
  display: flex;
  gap: 12px;
  .status-chip {
    background: ${theme.PRIMARYCOLOR};
    text-transform: uppercase;
  }
  @media (max-width: 1600px) {
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
export const NameWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLOR};
  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
    width: 100%;
  }
`
export const ComplaintsInfoContainer = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`
export const ComplaintsInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
export const ComplaintTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const ComplaintDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.DESCRIPTIONTEXTCOLOR};
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    text-transform: none;
    border-radius: 8px;
  }
  @media (max-width: 1600px) {
    justify-content: flex-end;
  }
  @media (max-width: 767px) {
    justify-content: flex-start;
    align-items: unset;
    width: 100%;
    button {
      width: 100%;
    }
  }
`

/**** Complaints Detail Page CSS *****/
export const ComplaintsDetailMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 150px 80px 40px;
  @media (max-width: 767px) {
    margin: 180px 16px 16px 16px;
    gap: 18px;
  }
`
export const ComplaintsDetailTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.TERTIARYCOLORDARK};
  @media (max-width: 767px) {
    padding-top: 0;
    font-size: 20px;
  }
`
export const ComplaintsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`
export const RespondentDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${theme.SECONDARYBORDERCOLOR};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  @media (max-width: 767px) {
    margin: 0;
  }
`
export const ComplaintIssueSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const RespondentTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYTEXTCOLOR};
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  padding-bottom: 16px;
`
export const RespondentInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 8px;
  }
`
export const ComplaintTimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
  max-width: 700px;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    min-width: unset;
    max-width: unset;
  }
`
export const TimelineContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`
export const TimelineTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
`
export const TimelineTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const TimelineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiTimelineItem-root {
    min-height: 120px;
  }
`
export const ComplaintStatus = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.PRIMARYCOLOR};
`
export const ComplaintTime = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const ComplaintDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 10px;
`
export const ComplaintStatusDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const ComplaintRespondentDetail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.PRIMARYTEXTCOLOR};
`
export const ComplaintSummaryCardWrapper = styled.div`
  background: ${theme.TERTIARYBACKGROUNDCOLOR};
  padding: 24px;
  border-radius: 12px;
  max-width: 720px;
  min-width: 560px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 460px;
    min-width: 320px;
    padding: 16px;
  }
`
export const ComplaintActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const ActionButtonWrapper = styled.div`
  button {
    background: ${theme.PRIMARYCOLOR};
    color: #fff;
    text-transform: none;
  }
`
export const ResolutionDetailWrapper = styled.div`
  border: 1px solid ${theme.PRIMARYBORDERCOLORDARK};
  background: ${theme.SECONDARYBACKGROUNDCOLOR};
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 460px;
    min-width: 320px;
    padding: 16px;
  }
`
export const ProductDetailSection = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`
export const ProductImageWrapper = styled.div`
  img {
    max-width: 82px;
  }
`
export const ProductDetailWrapper = styled.div`
  width: 100%;
`
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const ProductName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ProductQuantityWrapper = styled.div`
  display: flex;
  gap: 4px;
`
export const ProductQuantityTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ProductQuantity = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: ${theme.PRIMARYGREYCOLOR};
`
export const IssueImageWrapper = styled.div`
  display: flex;
  gap: 10px;
  img {
    max-width: 82px;
    max-height: 82px;
    object-fit: contain;
  }
`
export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
`
export const CardDetailWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export default useStyles
