import React, { useContext, useEffect, useState, useRef } from 'react'
import ReactGA from 'react-ga4'
import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import moment from 'moment'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  ButtonGroup,
  Skeleton,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useTheme } from 'context/newThemeContext'
import { ToastContext } from 'context/toastContext'
import { CartContext } from 'context/cartContext'
import { SearchContext } from 'context/searchContext'
import { LocationContext } from 'context/locationContext'
import Spinner from 'components/Loader'
import useCancellablePromise from 'api/cancelRequest'
import { deleteWithAuthentication, getCall, postCall, postLoginCall } from 'api/axios'
import { postCheckoutCall, getSelectCall } from 'api/axios'
import { formatIndianRupees, getOrCreateDeviceId, getUserId } from 'helper'
import { getValueFromCookie } from 'utils/cookies'
import { SSE_TIMEOUT } from 'constants/sse-waiting-time'
import { CalculateDiscount } from 'utils/helper'
import { checkPolygonAvailability, checkInterCityHyperLocal } from 'utils/serviceableHelper'
import { getErrorMessageFromErrorCode } from 'helper/errorCode'
import { updateCartItem } from 'components/application/cart/utils/updateCartItem'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import CustomizationRenderer from 'components/application/product-list/product-details/CustomizationRenderer'
import VariationsRenderer from 'components/application/product-list/product-details/VariationsRenderer'
import CalendarIcon from 'assets/svg/CalendarIcon'
import ReturnAndExchangeIcon from 'assets/svg/ReturnAndExchangeIcon'
import DeliveryCostIcon from 'assets/svg/DeliveryCostIcon'
import CostIcon from 'assets/svg/CostIcon'
import TermsAndConditionLogo from 'assets/svg/TermsAndConditionLogo'
import WishlistIcon from 'assets/svg/BharathamWishlistIcon'
import WishlistFilledIcon from 'assets/svg/BharathamWishlistFilled'
import ProductImage from 'assets/images/no_image_found.png'
import PreviousIcon from 'assets/svg/LeftArrow'
import NextIcon from 'assets/svg/RightArrow'
import useStyles, {
  DetailsContainer,
  ImgContainer,
  ProductContainer,
  MobileContainer,
  QuantityContainer,
  PurchaseButtonWrapper,
  BreadcrumbWrapper,
  MainWrapper,
  ProductDetailWrapper,
  ProductInfoWrapper,
  ProductName,
  StoreName,
  PriceWrapper,
  ProductPrice,
  OriginalPrice,
  Discount,
  ProductDeliveryDetailWrapper,
  DeliveryTitle,
  DeliveryDetailWrapper,
  InfoTitle,
  InfoDetail,
  DetailWrapper,
  ReturnDetailWrapper,
  TermsConditionWrapper,
  LogoWrapper,
  Content,
  AvailabeItem,
  ErrorMessage,
  ProductInformationWrapper,
  ProductDetailHeading,
  ProductInformationTitle,
  ProductInformationDescripton,
  PincodeAvailable,
  VariantsWrapper,
  ProductImagesWrapper,
  ImageContainer,
  IconButton,
  ProductImageSection,
  WishlistSection,
  WishlistWrapper,
  PriceQuantityContainer,
  ProductDetailSection,
  Title,
  ShowMoreText,
  DeliveryText,
  PincodeWrapper,
} from 'components/application/product-list/product-details/style'
import { isLoggedIn } from 'utils/validateToken'
import ModalComponent from 'components/common/Modal'
import LoginModal from 'views/LoginModal'
import Truck from 'assets/svg/Truck'
import PlaceOrderModal from 'views/OrderModal'

const ProductDetails = ({ productId }) => {
  const responseRef = useRef([])
  const { latitude, longitude } = useContext(LocationContext)
  const [orderModal, setOrderModal] = useState(false)
  const getPinCode = localStorage.getItem('pinCode')
  const LatLng = localStorage.getItem('latAndLng')
  const cityName = localStorage.getItem('city')
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useContext(ToastContext)
  const { fetchCartItems, cartItems } = useContext(CartContext)
  const { locationData: deliveryAddressLocation } = useContext(SearchContext)
  const { cancellablePromise } = useCancellablePromise()
  const [productPayload, setProductPayload] = useState()
  const [productDetails, setProductDetails] = useState({})
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [customization_state, setCustomizationState] = useState({})
  const [activeImage, setActiveImage] = useState('')
  const [customizationPrices, setCustomizationPrices] = useState(0)
  customizationPrices
  const [itemOutOfStock, setItemOutOfStock] = useState(false)
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [selectLoading, setSelectLoading] = useState(false)
  const [localQuantity, setLocalQuantity] = useState(1)
  const [showFullLongDesc, setShowFullLongLongDesc] = useState(false)
  const [showFullShortDesc, setShowFullShortDesc] = useState(false)
  const [showAllDetails, setShowAllDetails] = useState(false)
  const [itemDetails, setItemDetails] = useState({})
  const [expectedDate, setExpectedDate] = useState('')
  const [serviceable, setServiceable] = useState(null)
  const [product, setProduct] = useState()
  const [brand, setBrand] = useState({})
  const [loginModal, setLoginModal] = useState(false)
  const [providerDetails, setProviderDetails] = useState({})
  const eventTimeOutRef = useRef([])
  const updatedCartItems = useRef([])
  const [variationState, setVariationState] = useState([])
  const userId = getUserId()
  const [pinCode, setPinCode] = useState()
  const [validatePinCode, setValidatePinCode] = useState(false)
  const { setLocation } = useContext(LocationContext)
  const [error, setError] = useState({
    areaCode_error: '',
  })

  const checkAvailabilityForCartItems = async (cartProductItems, latitude, longitude, setServiceableCardItemId) => {
    if (cartProductItems.length) {
      const providerTags = cartProductItems
        .flatMap((item) => item?.provider_details?.tags)
        .filter((tag) => tag?.code === 'serviceability')

      const commonCategoryIds = []
      cartProductItems.forEach((item) => {
        const categoryId = item?.item_details?.category_id
        if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '13' && tag?.list[1]?.value === categoryId)
        ) {
          commonCategoryIds.push(categoryId)
          if (!setServiceableCardItemId) return // Exit early if setserviceableCardItemId is not provided
          setServiceableCardItemId(item?._id)
        } else if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '11' && tag?.list[1]?.value === categoryId)
        ) {
          // const test = filterPincodeToCheckIntercity(cartProductItems, '140301', '11')
          // console.log('test', test)
          return
        } else if (
          categoryId &&
          providerTags.some((tag) => tag?.list[2]?.value === '10' && tag?.list[1]?.value === categoryId)
        ) {
          //hyperlocal
          const checkAva = checkInterCityHyperLocal(cartProductItems, categoryId, '10', latitude, longitude)
          setServiceable(checkAva)
          return
        } else {
          //Pan India Code 12
          return
        }
      })

      await Promise.all(
        commonCategoryIds.map(async (categoryId) => {
          const checkAvailability = await checkPolygonAvailability(providerTags, categoryId, [latitude, longitude])
          setServiceable(checkAvailability)
        }),
      )
    }
  }

  useEffect(() => {
    checkAvailabilityForCartItems([product], latitude, longitude)
  }, [product, latitude, longitude])

  const updateQuantity = (increment) => {
    const maxCount = parseInt(productDetails?.quantity?.maximum?.count)
    setErrorMessage('') // Reset error message on each update

    setLocalQuantity((prevQuantity) => {
      let newQuantity = increment ? prevQuantity + 1 : prevQuantity - 1

      // Check if the new quantity exceeds the maximum count
      if (newQuantity > maxCount) {
        setErrorMessage('Cannot exceed maximum quantity')
        return prevQuantity // Keep the quantity unchanged
      } else {
        // Ensure quantity doesn't go below 1
        return Math.max(newQuantity, 1)
      }
    })
  }

  // const isMaxQuantityReached = localQuantity >= parseInt(productDetails?.quantity?.maximum?.count)

  // const updateProductCart = () => {
  //   const productId = cartItems
  //     .map((ele) => ele?.item)
  //     .filter((e) => {
  //       if (e?.local_id === productDetails?.id) return e
  //     })
  //   return productId[0]?.quantity?.count
  // }

  useEffect(() => {
    // const findCount = updateProductCart()
    // if (findCount) setLocalQuantity(findCount)
    if (productDetails.id) {
      getQuote()
    }
  }, [productDetails.id, getPinCode])

  const handleImageClick = (imageUrl) => {
    setActiveImage(imageUrl)
  }

  const getProductDetails = async (productId) => {
    const deviceId = await getOrCreateDeviceId()

    try {
      const data = await cancellablePromise(
        getCall(
          `${process.env.REACT_APP_BASE_PROTOCOL_URL}/clientApis/v2/item-details?id=${productId}&userId=${userId}&deviceId=${deviceId}`,
        ),
      )
      const { item_details } = data || {}
      const { bpp_details } = data || {}
      const { provider_details } = data || {}
      fetchCartItems()
      setProductPayload(data)

      setProviderDetails(provider_details)
      setProductDetails(item_details)
      setBrand(bpp_details)
      setProduct(data)
      setActiveImage(item_details?.descriptor?.symbol)
      getVariationGroups()
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
    }
  }

  const calculateSubtotal = (groupId, customization_state) => {
    let group = customization_state[groupId]
    if (!group) return

    let prices = group.selected.map((s) => s.price)
    setCustomizationPrices((prevState) => {
      return prevState + prices.reduce((a, b) => a + b, 0)
    })

    group?.childs?.map((child) => {
      calculateSubtotal(child, customization_state)
    })
  }

  let selectedCustomizationIds = []

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = () => {
    if (!productPayload?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = productPayload?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }

  function findMinMaxSeq(customizationGroups) {
    if (!customizationGroups || customizationGroups.length === 0) {
      return { minSeq: undefined, maxSeq: undefined }
    }

    let minSeq = Infinity
    let maxSeq = -Infinity

    customizationGroups.forEach((group) => {
      const seq = group.seq
      if (seq < minSeq) {
        minSeq = seq
      }

      if (seq > maxSeq) {
        maxSeq = seq
      }
    })

    return { minSeq, maxSeq }
  }

  findMinMaxSeq()

  function areCustomisationsSame(existingIds, currentIds) {
    if (existingIds.length !== currentIds.length) {
      return false
    }

    existingIds.sort()
    currentIds.sort()

    for (let i = 0; i < existingIds.length; i++) {
      if (existingIds[i] !== currentIds[i]) {
        return false
      }
    }

    return true
  }

  const addToCart = async (navigate = false, isIncrement = true) => {
    setAddToCartLoading(true)
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`

    // let subtotal = productPayload?.item_details?.price?.value

    const customisations = getCustomizations() ?? null

    if (customisations) {
      calculateSubtotal(customization_state['firstGroup']?.id, customization_state)
      // subtotal += customizationPrices
    }

    const payload = {
      customisations: getCustomizations(product),
      hasCustomisations: !!getCustomizations(product),
      customisationState: customization_state,
      local_id: productPayload?.local_id,
      id: productPayload?.id,

      provider: {
        id: productPayload?.provider_details.id,
      },
      quantity: {
        count: localQuantity,
      },
    }

    let cartItem = []
    cartItem = cartItems.filter((ci) => {
      return ci.item.id === payload.id
    })

    if (customisations) {
      cartItem = cartItem.filter((ci) => {
        return ci.item.customisations != null
      })
    }

    if (cartItem?.length > 0 && customisations) {
      cartItem = cartItem.filter((ci) => {
        return ci.item.customisations.length === customisations.length
      })
    }

    if (cartItem?.length === 0) {
      await postCall(url, payload)
      fetchCartItems()
      setAddToCartLoading(false)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item added to cart successfully.',
        },
      })
      if (navigate) {
        history.push('/cart')
      }
    } else {
      const currentCount = parseInt(cartItem[0]?.item?.quantity?.count)
      const maxCount = parseInt(cartItem[0]?.item?.product?.quantity?.maximum.count)

      if (navigate) {
        history.push('/cart')
      }

      if (currentCount < maxCount) {
        if (!customisations) {
          await updateCartItem(cartItems, localQuantity, isIncrement, cartItem[0]._id)
          fetchCartItems()
          setAddToCartLoading(false)
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.success,
              message: 'Item quantity updated in your cart.',
            },
          })
        } else {
          const currentIds = customisations.map((item) => item.id)
          let matchingCustomisation = null

          for (let i = 0; i < cartItem.length; i++) {
            let existingIds = cartItem[i].item.customisations.map((item) => item.id)
            const areSame = areCustomisationsSame(existingIds, currentIds)
            if (areSame) {
              matchingCustomisation = cartItem[i]
            }
          }

          if (matchingCustomisation) {
            await updateCartItem(cartItems, isIncrement, matchingCustomisation._id)
            setAddToCartLoading(false)
            fetchCartItems()
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.success,
                message: 'Item quantity updated in your cart.',
              },
            })
          } else {
            await postCall(url, payload)
            fetchCartItems()
            setAddToCartLoading(false)
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.success,
                message: 'Item added to cart successfully.',
              },
            })
          }
        }
      } else {
        setAddToCartLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: `The maximum available quantity for item is already in your cart.`,
          },
        })
      }
    }
  }

  // fetch product details
  useEffect(() => {
    getProductDetails(productId)
  }, [deliveryAddressLocation, productId])

  const renderVegNonVegTag = () => {
    const FnB = 'ONDC:RET11'
    const grocery = 'ONDC:RET10'

    if (productPayload?.context?.domain == grocery || productPayload?.context?.domain == FnB) {
      const tags = productPayload?.item_details.tags
      let category = 'veg'

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].code === 'veg_nonveg' && tags[i]?.list?.[0]?.value) {
          const vegNonVegValue = tags[i].list[0].value

          if (vegNonVegValue === 'yes' || vegNonVegValue === 'Yes') {
            category = 'veg'
          } else if (vegNonVegValue === 'no') {
            category = 'nonveg'
          } else if (vegNonVegValue === 'egg') {
            category = 'egg'
          }
        }
      }

      const getTagColor = () => {
        if (category === 'veg') {
          return '#008001'
        } else if (category === 'nonveg') {
          return 'red'
        } else {
          return '#008001'
        }
      }

      const getTextColor = () => {
        if (category === 'veg') {
          return '#419E6A'
        } else if (category === 'nonveg') {
          return 'red'
        } else {
          return 'red'
        }
      }

      const map = {
        veg: 'Veg',
        nonveg: 'Non Veg',
        egg: 'EGG',
      }

      return (
        <Grid container alignItems="center" sx={{ marginBottom: 1.5 }}>
          <div className={classes.square} style={{ borderColor: getTagColor() }}>
            <div className={classes.circle} style={{ backgroundColor: getTagColor() }}></div>
          </div>
          <Typography variant="body" color={getTextColor()} sx={{ fontWeight: '600' }}>
            {map[category]}
          </Typography>
        </Grid>
      )
    }

    return null
  }

  const renderStockStatus = () => {
    if (parseInt(productDetails?.quantity?.available?.count) >= 1) {
      return (
        <Typography variant="body" sx={{ marginBottom: 1 }} style={{ color: '#3A3A88' }}>
          <DoneIcon color="success" fontSize="small" /> In stock
        </Typography>
      )
    } else {
      return (
        <Grid container alignItems="center" sx={{ marginBottom: 1 }}>
          <CloseIcon color="error" fontSize="small" />
          <Typography variant="body1" color="#D83232">
            Out of Stock
          </Typography>
        </Grid>
      )
    }
  }

  const renderAttributeDetails = () => {
    return Object.keys(productPayload?.attributes).map((key, index) => {
      // Replace underscores with spaces
      const formattedKey = key.replace(/_/g, ' ')
      const formattedAttributes = productPayload?.attributes[key].replace(/_/g, ' ')
      return (
        <ProductDetailSection key={index} container className={classes.keyValueContainer}>
          <Title>
            <ProductInformationTitle>{formattedKey}</ProductInformationTitle>
          </Title>
          <div>
            <ProductInformationDescripton>{formattedAttributes}</ProductInformationDescripton>
          </div>
        </ProductDetailSection>
      )
    })
  }

  const renderItemDetails = () => {
    const detailsToShow = 8

    const formattedShortDesc = productPayload?.item_details?.descriptor?.short_desc.replace(/_/g, ' ')
    const formattedLongDesc = productPayload?.item_details?.descriptor?.long_desc.replace(/_/g, ' ')

    let returnWindowValue = 'NA'

    if (productPayload?.item_details?.['@ondc/org/return_window']) {
      const duration = moment.duration(productPayload.item_details['@ondc/org/return_window'])
      if (duration.asSeconds() === 0 || duration.asMinutes() < 1) {
        returnWindowValue = 'NA'
      } else {
        returnWindowValue = duration.humanize()
      }
    }

    const truncateDescription = (desc, maxLength) => {
      return desc.length > maxLength ? `${desc.substring(0, maxLength)}...` : desc
    }

    const maxLength = 300
    const shortDesc = formattedShortDesc || ''
    const truncatedShortDesc = truncateDescription(shortDesc, maxLength)
    const fullShortDesc = showFullShortDesc ? shortDesc : truncatedShortDesc

    const longDesc = formattedLongDesc || ''
    const truncatedLongDesc = truncateDescription(longDesc, maxLength)
    const fullLongDesc = showFullLongDesc ? longDesc : truncatedLongDesc

    const data = {
      'Short Description': fullShortDesc,
      'Long Description': fullLongDesc,
      Code: productPayload?.item_details?.descriptor?.code,
      'Available on COD': productPayload?.item_details?.['@ondc/org/available_on_cod'] ? 'Yes' : 'No',
      Cancellable: productPayload?.item_details?.['@ondc/org/cancellable'] ? 'Yes' : 'No',
      'Return window value': returnWindowValue,
      Returnable: productPayload?.item_details?.['@ondc/org/returnable'] ? 'Yes' : 'No',
      Ingredients: productPayload?.item_details?.['@ondc/org/statutory_reqs_prepackaged_food']?.ingredients_info,
      'Customer care': productPayload?.item_details?.['@ondc/org/contact_details_consumer_care'],
      'Manufacturer name':
        productPayload?.item_details?.['@ondc/org/statutory_reqs_packaged_commodities']?.manufacturer_or_packer_name,
      'Manufacturer address':
        productPayload?.item_details?.['@ondc/org/statutory_reqs_packaged_commodities']?.manufacturer_or_packer_address,
    }

    const visibleData = showAllDetails ? Object.keys(data) : Object.keys(data).slice(0, detailsToShow)

    return (
      <div>
        {visibleData.map((key, index) => {
          const formattedKey = key.replace(/_/g, ' ')

          const value = data[key]
          if (value !== null && value !== undefined) {
            return (
              <ProductDetailSection container className={classes.keyValueContainer} key={index}>
                <Title>
                  <ProductInformationTitle>{formattedKey}</ProductInformationTitle>
                </Title>
                <div>
                  {['Short Description', 'Long Description'].includes(key) ? (
                    <>
                      <ProductInformationDescripton>
                        {key === 'Short Description' ? fullShortDesc : fullLongDesc}
                      </ProductInformationDescripton>
                      {key === 'Short Description' && shortDesc.length > maxLength && (
                        <ShowMoreText onClick={() => setShowFullShortDesc(!showFullShortDesc)}>
                          {showFullShortDesc ? 'Show less' : 'Show more'}
                        </ShowMoreText>
                      )}
                      {key === 'Long Description' && longDesc.length > maxLength && (
                        <ShowMoreText onClick={() => setShowFullLongLongDesc(!showFullLongDesc)}>
                          {showFullLongDesc ? 'Show less' : 'Show more'}
                        </ShowMoreText>
                      )}
                    </>
                  ) : (
                    <ProductInformationDescripton>{value}</ProductInformationDescripton>
                  )}
                </div>
              </ProductDetailSection>
            )
          }

          return null
        })}
        {Object.keys(data).length > detailsToShow && (
          <ShowMoreText onClick={() => setShowAllDetails(!showAllDetails)}>
            {showAllDetails ? 'View less' : 'View more'}
          </ShowMoreText>
        )}
      </div>
    )
  }

  let rangePriceTag = null
  if (
    productDetails &&
    productDetails?.price &&
    productDetails?.price?.tags &&
    productDetails?.price?.tags.length > 0
  ) {
    const findRangePriceTag = productDetails?.price?.tags.find((item) => item.code === 'range')
    if (findRangePriceTag) {
      const findLowerPriceObj = findRangePriceTag.list.find((item) => item.code === 'lower')
      const findUpperPriceObj = findRangePriceTag.list.find((item) => item.code === 'upper')
      rangePriceTag = {
        maxPrice: findUpperPriceObj.value,
        minPrice: findLowerPriceObj.value,
      }
    }
  }

  // let subtotal = productPayload?.item_details?.price?.value
  const customisations = getCustomizations() ?? null

  let itemProductData = {
    id: productPayload?.id,
    local_id: productPayload?.local_id,
    // tags: productPayload?.item_details?.tags,
    customisationState: customization_state,
    quantity: {
      count: localQuantity,
    },
    provider: {
      id: productPayload?.provider_details?.id,
      local_id: productPayload?.provider_details?.local_id,
      locations: productPayload?.locations?.map((location) => ({
        id: location.id,
        local_id: location.local_id,
      })),
    },

    customisations,
    hasCustomisations: !!customisations,
  }

  const currentPincode = localStorage.getItem('currentpinCode')

  const getQuote = async () => {
    setSelectLoading(true)
    responseRef.current = []

    try {
      let selectPayload = {
        context: {
          domain: productPayload?.context?.domain,
          city: getPinCode ? getPinCode : currentPincode,
        },
        message: {
          cart: {
            items: [itemProductData],
          },
          fulfillments: [
            {
              end: {
                location: {
                  gps: `${latitude},${longitude}`,
                  address: {
                    area_code: `${getPinCode ? getPinCode : currentPincode}`,
                  },
                },
              },
            },
          ],
        },
        userId: userId,
      }

      const data = await cancellablePromise(postCheckoutCall('/clientApis/v2/select', [selectPayload]))

      //Error handling workflow eg, NACK
      // const isNACK = data.find((item) => item.error && item?.message?.ack?.status === 'NACK')
      const isNACK = data.find((item) => item?.message?.ack?.status === 'NACK')
      if (isNACK) {
        setSelectLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message:
              'The Seller is not reachable to accept the orders. Please try again later. Thank you for being patience.',
          },
        })
      } else {
        onFetchQuote(
          data?.map((txn) => {
            const { context } = txn
            return context?.message_id
          }),
        )
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data[0]?.message,
        },
      })
      setSelectLoading(false)
      // setCheckoutLoading(false)
    }
  }

  // getQuote()

  function onFetchQuote(message_id) {
    eventTimeOutRef.current = []

    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    if (message_id) {
      message_id.forEach((id) => {
        let es = new window.EventSourcePolyfill(
          `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
          header,
        )

        es.addEventListener('on_select', (e) => {
          const { messageId } = JSON.parse(e.data)
          onGetQuote(messageId)

          // Close the event connection as soon as the response is received
          es.close()
          const eventTimeout = eventTimeOutRef.current.find((item) => item.eventSource === es)
          if (eventTimeout) {
            clearTimeout(eventTimeout.timer)
            eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
          }
        })

        const timer = setTimeout(() => {
          eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
            eventSource.close()
            clearTimeout(timer)
          })

          if (responseRef.current.length <= 0) {
            // setCheckoutLoading(false);
            setSelectLoading(false)
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message: 'Cannot fetch details for this product',
              },
            })
            history.replace('/products')
            return
          }
        }, SSE_TIMEOUT)

        eventTimeOutRef.current = [
          ...eventTimeOutRef.current,
          {
            eventSource: es,
            timer,
          },
        ]
      })
    }
  }

  const onGetQuote = async (message_id) => {
    try {
      // setCheckoutLoading(true)
      const data = await cancellablePromise(getSelectCall(`/clientApis/v2/on_select?messageIds=${message_id}`))
      responseRef.current = [...responseRef.current, data[0]]
      if (data[0]?.error) {
        // Close the loader
        // setCheckoutLoading(false)
        const errorMessage = getErrorMessageFromErrorCode(data[0].error?.code)
        if (data[0].error.message === 'Pincode not servicable') {
          setServiceable(data[0].error.message)
          setSelectLoading(false)
        } else {
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: errorMessage, // Use the error message from the API response
            },
          })
          setSelectLoading(false)
        }
        // Display the error message in the toast notification

        // Return from the function or take any other necessary action
        return
      }

      data[0]?.message.quote.items.forEach((item) => {
        const findItemIndexFromCart = updatedCartItems.current.findIndex((prod) => prod.item.product.id === item.id)
        if (findItemIndexFromCart > -1) {
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillment_id = item.fulfillment_id
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillments = data[0].message.quote.fulfillments
        }
      })
      setItemDetails(data[0])
      setSelectLoading(false)
      localStorage.setItem('updatedCartItems', JSON.stringify(responseRef.current))
    } catch (err) {
      setSelectLoading(false)
      // setCheckoutLoading(false)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err.message,
        },
      })
    }
  }

  // useEffect(() => {
  //   if (productDetails.id) {
  //     getQuote()
  //   }
  // }, [productDetails.id])

  const CalculateDuration = (data) => {
    const durationInMilliseconds = moment.duration(data['@ondc/org/return_window']).asMilliseconds()
    const duration = moment.duration(durationInMilliseconds)

    const days = duration.days()
    const hours = duration.hours()
    const mins = duration.minutes()

    if (days > 1) {
      return <span>{days} Days</span>
    } else if (hours > 0) {
      return <span>{hours} hrs</span>
    } else if (days == 1) return <span>{days} Day</span>
    else {
      return <span>{mins > 0 ? <span>{mins} mins</span> : <span>Not Available</span>}</span>
    }
  }

  const calculateExpectedDelivery = (timeStampString, tat) => {
    const deliveryDate = moment(timeStampString).add(moment.duration(tat))
    return deliveryDate.toISOString()
  }

  const discount = CalculateDiscount(productDetails?.price?.maximum_value, productDetails?.price?.value)
  const maxProductValue = parseFloat(productDetails?.price?.maximum_value).toFixed(2)

  useEffect(() => {
    if (itemDetails?.message?.quote?.fulfillments) {
      const timestamp = itemDetails?.context?.timestamp
      const tatTime = itemDetails?.message?.quote?.fulfillments[0]
      const tat = moment.duration(tatTime['@ondc/org/TAT']).asMilliseconds()
      const updateTat = calculateExpectedDelivery(timestamp, tat)
      setExpectedDate(updateTat)
    }
  }, [itemDetails?.message])

  const { theme } = useTheme()
  const getVariationGroups = () => {
    const parentId = productPayload?.item_details?.parent_item_id
    const parentData = productPayload?.categories?.find((item) => item.id === parentId)

    if (parentData) {
      const groupInfo = new Set() // Use a Set to store unique items

      for (const tag of parentData.tags) {
        if (tag.code === 'attr') {
          const nameTag = tag.list.find((item) => item.code === 'name')
          const seqTag = tag.list.find((item) => item.code === 'seq')

          if (nameTag && seqTag) {
            const nameParts = nameTag.value.split('.')
            const name = nameParts[nameParts.length - 1]
            const seq = parseInt(seqTag.value)

            const item = { name, seq }

            // Convert the object to a JSON string to ensure uniqueness
            const itemString = JSON.stringify(item)

            // Check if the item already exists in the Set
            if (!groupInfo.has(itemString)) {
              // If it doesn't exist, add it to the Set
              groupInfo.add(itemString)
            }
          }
        }
      }
    } else {
      // setNoVariations(true)
    }
  }

  useEffect(() => {
    getVariationGroups()
  }, [deliveryAddressLocation])

  const sliderSettings = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          infinite: false,
          autoplay: false,
          arrows: false,
        },
      },
    ],
    nextArrow:
      Array.isArray(productDetails?.descriptor?.images) && productDetails.descriptor.images.length >= 4 ? (
        <IconButton>
          <NextIcon />
        </IconButton>
      ) : null,
    prevArrow:
      Array.isArray(productDetails?.descriptor?.images) && productDetails.descriptor.images.length >= 4 ? (
        <IconButton>
          <PreviousIcon />
        </IconButton>
      ) : null,
  }

  const isOutOfStock = parseInt(productDetails?.quantity?.available?.count) < 1

  const handleWishlist = async (product, e) => {
    e.stopPropagation()
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    const deviceId = await getOrCreateDeviceId()
    const { item_details } = product
    const url = `/clientApis/v2/wishlist/${userId}/${deviceId}`
    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      setProduct((prevItems) => {
        if (!Array.isArray(prevItems)) {
          prevItems = []
        }

        return prevItems.map((item) =>
          item?.item_details?.id === item_details?.id ? { ...item, wishlistAdded: false } : item,
        )
      })
      const res = await postLoginCall(url, payload)
      getProductDetails(productId)
      if (res.status !== 'error') {
        localStorage.setItem('wishlistItems', JSON.stringify(res))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to wishlist successfully.',
          },
        })
      } else {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'It looks like the item already exists in the Wishlist. Please check',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, you cannot add this item to your Wishlist due to technical glitch. Please try again.',
        },
      })
    }
  }

  const handleRemoveFromWishlist = async (item_details, e) => {
    e.stopPropagation()
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    try {
      const deviceId = await getOrCreateDeviceId()
      const url = `/clientApis/v2/item/wishlist/${userId}/${deviceId}/${item_details?.id}`
      await deleteWithAuthentication(url)
      getProductDetails(productId)

      setProduct((prevItems) => {
        if (!Array.isArray(prevItems)) {
          prevItems = []
        }

        return prevItems.map((item) =>
          item?.item_details?.id === item_details?.id ? { ...item, wishlistAdded: false } : item,
        )
      })

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item removed from your Wishlist',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching products. Please try again.',
        },
      })
    }
  }

  const checkPinCode = () => {
    setError((error) => ({
      ...error,
      areaCode_error: '',
    }))

    let pin = pinCode !== undefined ? pinCode : getPinCode ? getPinCode : currentPincode

    if (!pin || pin?.length !== 6 || !/^\d+$/.test(pin)) {
      setError((error) => ({
        ...error,
        areaCode_error: 'Pin code must contain only 6 digits',
      }))
      return false
    }

    return true
  }

  const getPinCodeAddressRequest = async () => {
    try {
      const data = await getCall(
        `/clientApis/v2/map/getCordinates?postcode=${pinCode ? pinCode : getPinCode ? getPinCode : currentPincode}`,
      )
      setLocation(data?.data?.latitude, data?.data?.longitude)
      localStorage.setItem('pinCode', pinCode ? pinCode : getPinCode ? getPinCode : currentPincode)
      localStorage.setItem('latAndLng', data?.data?.latitude.toFixed(6), data?.data?.longitude.toFixed(6))

      return data
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Invalid Pin Code',
        },
      })
    }
  }

  const handleSubmit = () => {
    setValidatePinCode(true)
    if (checkPinCode()) {
      getPinCodeAddressRequest()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [pinCode])

  const handleBuyNowClick = () => {
    ReactGA.event({
      category: 'Search',
      action: 'Click',
      label: 'Buy Now',
    })

    setSelectedProduct(productPayload)

    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    } else {
      setOrderModal(true)
    }
  }

  const getDeliveryPrice = (items) => {
    const deliveryItem = items.find((item) => item['@ondc/org/title_type'] === 'delivery')
    return deliveryItem ? parseFloat(deliveryItem.price.value) : 0
  }

  return (
    <ProductContainer theme={theme}>
      {productPayload == null ? (
        <div className={classes.emptySpace}>
          <Spinner />
        </div>
      ) : (
        <MainWrapper>
          <BreadcrumbWrapper>
            <Breadcrumbs aria-label="breadcrumb">
              <MuiLink component={Link} underline="hover" color="inherit" to="/">
                <Typography className="text-color">Home</Typography>
              </MuiLink>
              <MuiLink component={Link} underline="hover" color="inherit" to={`/brand?brandId=${providerDetails?.id}`}>
                <Typography className="text-color">{brand?.name}</Typography>
              </MuiLink>
              <Typography className="title-color">{productDetails?.descriptor?.name}</Typography>
            </Breadcrumbs>
          </BreadcrumbWrapper>
          <DetailsContainer theme={theme}>
            <MobileContainer>
              <Grid item xs={7}>
                <ProductImageSection>
                  <WishlistSection>
                    <WishlistWrapper>
                      {product.wishlistAdded ? (
                        <WishlistFilledIcon
                          className="filled-icon"
                          onClick={async (e) => {
                            handleRemoveFromWishlist(product.item_details, e)
                          }}
                        />
                      ) : (
                        <WishlistIcon onClick={(e) => handleWishlist(product, e)} />
                      )}
                    </WishlistWrapper>
                  </WishlistSection>

                  <ImgContainer>
                    {activeImage ? (
                      <img
                        src={activeImage}
                        onError={(e) => {
                          e.target.src =
                            'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                        }}
                      />
                    ) : (
                      <img src="https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg" />
                    )}
                  </ImgContainer>
                </ProductImageSection>

                <ProductImagesWrapper>
                  {activeImage && (
                    <Slider {...sliderSettings} arrows>
                      {productDetails?.descriptor?.images?.map((item, idx) => {
                        return (
                          <ImageContainer
                            key={idx}
                            style={{
                              borderColor: item === activeImage ? '#3A3A88' : 'lightgrey',
                            }}
                            onClick={() => handleImageClick(item)}
                          >
                            <div className={classes.greyContainer}>
                              <img
                                className={classes.moreImage}
                                src={item ? item : ProductImage}
                                onError={(e) => {
                                  e.target.src =
                                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                                }}
                              />
                            </div>
                          </ImageContainer>
                        )
                      })}
                    </Slider>
                  )}
                </ProductImagesWrapper>
              </Grid>

              <Grid item xs={5}>
                <ProductDetailWrapper>
                  {renderVegNonVegTag()}
                  {renderStockStatus()}
                  <ProductInfoWrapper>
                    <div>
                      <ProductName>{productDetails?.descriptor?.name}</ProductName>
                      <StoreName onClick={() => history.push(`/brand?brandId=${providerDetails?.id}`)}>
                        {brand?.name}
                      </StoreName>
                    </div>

                    {rangePriceTag ? (
                      <Grid container alignItems="center" sx={{ marginBottom: 1 }}>
                        <Typography variant="h4" sx={{ fontWeight: 500, fontSize: '20px' }}>
                          {`₹${rangePriceTag?.minPrice} - ₹${rangePriceTag?.maxPrice}`}
                        </Typography>
                      </Grid>
                    ) : (
                      <PriceQuantityContainer>
                        {parseInt(productDetails?.quantity?.available?.count) >= 1 && (
                          <>
                            <PriceWrapper>
                              <ProductPrice>₹{formatIndianRupees(productDetails?.price?.value)}</ProductPrice>
                              {maxProductValue > productDetails?.price?.value && (
                                <OriginalPrice>₹{formatIndianRupees(maxProductValue)}</OriginalPrice>
                              )}
                              {discount > 0 && <Discount>{Math.round(discount)}%</Discount>}
                            </PriceWrapper>
                            <QuantityContainer>
                              <ButtonGroup
                                variant={'contained'}
                                fullWidth
                                className="quantity-button"
                                disabled={
                                  !parseInt(productDetails?.quantity?.available?.count) >= 1 ||
                                  itemOutOfStock ||
                                  addToCartLoading
                                }
                              >
                                <Button className="decrease-quantity" onClick={() => updateQuantity(false)}>
                                  -
                                </Button>
                                <div className="quantity" variant={'outlined'}>
                                  {localQuantity}
                                </div>
                                {/* <Button onClick={() => updateQuantity(true)  disabled={isMaxQuantityReached}}>+</Button> */}
                                <Button onClick={() => updateQuantity(true)}>+</Button>
                              </ButtonGroup>
                            </QuantityContainer>
                          </>
                        )}
                      </PriceQuantityContainer>
                    )}
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                  </ProductInfoWrapper>

                  {/* {!parseInt(productDetails?.quantity?.available?.count) >= 1 && (
                    <Grid container justifyContent="center" className={classes.outOfStock}>
                      <Typography variant="body" color="#D83232">
                        Item is out of Stock
                      </Typography>
                    </Grid>
                  )} */}

                  {parseInt(productDetails?.quantity?.available?.count) >= 1 && (
                    <VariantsWrapper>
                      <VariationsRenderer
                        productPayload={productPayload}
                        variationState={variationState}
                        setVariationState={setVariationState}
                        chartImage={productPayload?.attributes?.size_chart || ''}
                        isFashion={productPayload?.context?.domain === 'ONDC:RET12'}
                      />
                    </VariantsWrapper>
                  )}
                  <CustomizationRenderer
                    productPayload={productPayload}
                    customization_state={customization_state}
                    setCustomizationState={setCustomizationState}
                    setItemOutOfStock={setItemOutOfStock}
                  />

                  <Grid container alignItems="center" sx={{ marginTop: 2.5 }}>
                    <PurchaseButtonWrapper>
                      <Button
                        variant={'contained'}
                        className="cartButton"
                        sx={{
                          flex: 1,
                          marginRight: '16px',
                          textTransform: 'none',
                        }}
                        onClick={() => {
                          addToCart(false, true)
                          ReactGA.event({
                            category: 'Button',
                            action: 'Click',
                            label: 'Add To Card',
                          })
                        }}
                        disabled={
                          !parseInt(productDetails?.quantity?.available?.count) >= 1 ||
                          itemOutOfStock ||
                          addToCartLoading
                        }
                      >
                        {addToCartLoading ? (
                          <Spinner />
                        ) : (
                          <div>
                            {/* <CartIcon /> */}
                            Add to Cart
                          </div>
                        )}
                      </Button>
                      <Button
                        className="orderNow"
                        variant="outlined"
                        sx={{ flex: 1, textTransform: 'none' }}
                        disabled={!parseInt(productDetails?.quantity?.available?.count) >= 1 || itemOutOfStock}
                        onClick={() => {
                          handleBuyNowClick()
                        }}
                      >
                        Buy Now
                      </Button>
                    </PurchaseButtonWrapper>
                  </Grid>
                  <ProductDeliveryDetailWrapper style={{ opacity: isOutOfStock ? 0.5 : 1 }}>
                    <DeliveryTitle>Delivery & Return Policy</DeliveryTitle>
                    {selectLoading ? (
                      <Skeleton animation="wave" variant="rounded" height={60} />
                    ) : (
                      <DeliveryDetailWrapper>
                        <PincodeAvailable>{serviceable}</PincodeAvailable>

                        <DetailWrapper>
                          <CalendarIcon />
                          <InfoTitle>Order Today To Get By</InfoTitle>
                          <InfoDetail>
                            {expectedDate
                              ? moment(expectedDate).isSame(moment(), 'day')
                                ? moment(expectedDate).format('Do MMM')
                                : `${moment().format('Do MMM')} - ${moment(expectedDate).format('Do MMM')}`
                              : LatLng
                                ? 'Not Available'
                                : 'Enter pincode for delivery date'}
                          </InfoDetail>
                        </DetailWrapper>
                        <DetailWrapper>
                          <ReturnAndExchangeIcon />
                          <ReturnDetailWrapper>
                            <InfoTitle>
                              Return & Exchange Accepted Within <span> {CalculateDuration(productDetails)}</span>
                            </InfoTitle>
                          </ReturnDetailWrapper>
                        </DetailWrapper>
                        <DetailWrapper>
                          <DeliveryCostIcon />
                          <InfoTitle>Delivery Cost :</InfoTitle>
                          <InfoDetail>
                            {itemDetails?.message?.quote?.quote?.breakup
                              ? `₹ ${getDeliveryPrice(itemDetails?.message?.quote?.quote?.breakup)}`
                              : ' 0 '}
                          </InfoDetail>
                        </DetailWrapper>
                        <DetailWrapper>
                          <CostIcon />
                          <InfoTitle>Cash on Delivery: </InfoTitle>
                          <div>
                            {productDetails['@ondc/org/available_on_cod'] ? (
                              <AvailabeItem>Available</AvailabeItem>
                            ) : (
                              <span>Not Available</span>
                            )}
                          </div>
                        </DetailWrapper>
                        <DetailWrapper>
                          <Truck />
                          <InfoTitle>Dispatches from: </InfoTitle>
                          <div>
                            {product ? (
                              <span>{product?.location_details?.address?.city}</span>
                            ) : (
                              <span>Not Available</span>
                            )}
                          </div>
                        </DetailWrapper>
                        <DetailWrapper>
                          <DeliveryText>
                            Deliver to -
                            {getPinCode || currentPincode ? (
                              <>
                                {getPinCode ? getPinCode : currentPincode} , {cityName}
                              </>
                            ) : (
                              <PincodeWrapper>
                                <TextField
                                  required
                                  fullWidth
                                  id="pin_code-input"
                                  name="pin_code"
                                  size="small"
                                  placeholder="Enter Pincode"
                                  variant="standard"
                                  className="pincode"
                                  value={pinCode !== undefined ? pinCode : getPinCode ? getPinCode : currentPincode}
                                  inputProps={{
                                    maxLength: 6,
                                  }}
                                  onChange={(event) => {
                                    const regexp = /^[0-9]+$/
                                    if (!regexp.test(event.target.value) && event.target.value !== '') return
                                    const areaCode = event.target.value
                                    setPinCode(areaCode)
                                    setError((error) => ({
                                      ...error,
                                      areaCode_error: '',
                                    }))
                                  }}
                                  error={!!(validatePinCode && error.areaCode_error)}
                                  helperText={(validatePinCode && error.areaCode_error) || ''}
                                  onBlur={() => {
                                    if (validatePinCode) {
                                      checkPinCode()
                                    }
                                  }}
                                />
                                <div>
                                  <Button className="orderNow" variant="text" onClick={handleSubmit}>
                                    Submit
                                  </Button>
                                </div>
                              </PincodeWrapper>
                            )}
                          </DeliveryText>
                        </DetailWrapper>
                      </DeliveryDetailWrapper>
                    )}
                  </ProductDeliveryDetailWrapper>
                  <TermsConditionWrapper>
                    <LogoWrapper>
                      <TermsAndConditionLogo />
                    </LogoWrapper>
                    <Content>
                      Bharatham Purchase Protection :
                      <span>
                        Shop confidently on Bharatham knowing if something goes wrong with an order, we&apos;ve got your
                        back for all eligible purchases -<a href="/purchase-protection"> Check programme terms</a>.
                      </span>
                    </Content>
                  </TermsConditionWrapper>
                </ProductDetailWrapper>
              </Grid>
            </MobileContainer>
          </DetailsContainer>
          <ProductInformationWrapper>
            <Accordion elevation={0} square defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordian">
                <ProductDetailHeading>Product Details</ProductDetailHeading>
                <Divider />
              </AccordionSummary>
              <AccordionDetails className="accordian-detail">
                {renderAttributeDetails()}
                {renderItemDetails()}
              </AccordionDetails>
            </Accordion>
          </ProductInformationWrapper>
        </MainWrapper>
      )}

      {orderModal && (
        <ModalComponent
          open={orderModal}
          onClose={() => setOrderModal(false)}
          title="Get Ready To Shop !"
          titleBg={true}
        >
          <PlaceOrderModal
            onClose={() => setOrderModal(false)}
            product={selectedProduct}
            localQuantity={localQuantity}
          />
        </ModalComponent>
      )}
      {loginModal && (
        <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
          <LoginModal onClose={() => setLoginModal(false)} />
        </ModalComponent>
      )}
    </ProductContainer>
  )
}

export default ProductDetails
