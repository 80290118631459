import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Chip from '@mui/material/Chip'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import moment from 'moment'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import useCancellablePromise from 'api/cancelRequest'
import { getSelectCall, postCheckoutCall } from 'api/axios'
import { getValueFromCookie } from 'utils/cookies'
import { SSE_TIMEOUT } from 'constants/sse-waiting-time'
import Loading from 'components/shared/loading/loading'
import ReturnOrderModal from 'components/orders/orderDetails/returnOrderModal'
import CancelOrderModal from 'components/orders/orderDetails/cancelOrderModal'
import IssueOrderModal from 'components/orders/orderDetails/issueOrderModal'
import ModalComponent from 'components/common/Modal'
import GetStatusIcon from 'assets/svg/GetStatusIcon'
import useStyles, {
  InfoSection,
  StatusWrapper,
  ProductDetailSection,
  Wrapper,
  ProductWrapper,
  ProductImage,
  ButtonWrapper,
  OrderPaymentWrapper,
  PaymentTitle,
  PaymentValue,
  PaymentWrapper,
  PaymentHeading,
  TotalValue,
  ReturnHeading,
  ReturnItemsWrapper,
  ReturnWrapper,
  CancelWrapper,
  ItemContentWrapper,
  ItemTitle,
  ItemQuantity,
  ItemStatusWrapper,
  RefundedAmountValue,
} from './style'
import { formatIndianRupees } from 'helper'

const OrderSummary = ({ orderDetails, onUpdateOrder, onUpdateTrakingDetails }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [itemQuotes, setItemQuotes] = useState(null)
  const [cancelledItems, setCancelledItems] = useState([])
  const [issueData, setIssueData] = useState()
  const [issueButton, setIssueButton] = useState(true)
  const [returnOrCancelledItems, setReturnOrCancelledItems] = useState([])
  const dispatch = useContext(ToastContext)
  const [quoteItemInProcessing, setQuoteItemInProcessing] = useState(null)
  const [toggleReturnOrderModal, setToggleReturnOrderModal] = useState(false)
  const [toggleCancelOrderModal, setToggleCancelOrderModal] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [allNonCancellable, setAllNonCancellable] = useState(false)
  const [statusLoading, setStatusLoading] = useState(false)
  const [isIssueRaised, setIsIssueRaised] = useState(false)
  const [issueLoading, setIssueLoading] = useState(false)
  const [toggleIssueModal, setToggleIssueModal] = useState(false)
  const statusEventSourceResponseRef = useRef(null)
  const eventTimeOutRef = useRef([])
  const [trackOrderLoading, setTrackOrderLoading] = useState(false)
  const trackOrderRef = useRef(null)
  const trackEventSourceResponseRef = useRef(null)
  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  // use this function to dispatch error
  function dispatchToast(message, type) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  const isItemCustomization = (tags) => {
    let isCustomization = false
    tags?.forEach((tag) => {
      if (tag.code === 'type') {
        tag?.list?.forEach((listOption) => {
          if (listOption.code === 'type' && listOption.value == 'customization') {
            isCustomization = true
            return true
          }
        })
      }
    })
    return isCustomization
  }

  useEffect(() => {
    if (issueData?.issues?.length) {
      // Filter issues by sub_category === 'FLM02'
      const filteredIssues = issueData.issues.filter((item) => item?.sub_category === 'FLM02')
      const closedIssue = filteredIssues.filter((issue) => issue.issue_status === 'Close')
      if (filteredIssues.length === closedIssue.length) setIssueButton(true)
      else {
        setIssueButton(false)
      }
    }
  }, [issueData])

  useEffect(() => {
    try {
      if (orderDetails) {
        if (orderDetails.updatedQuote) {
          // const provided_by = orderDetails?.provider?.descriptor?.name
          let uuid = 0
          const breakup = orderDetails.updatedQuote.breakup
          const all_items = breakup?.map((break_up_item) => {
            const items = orderDetails.items
            const itemIndex = items.findIndex((one) => one.id === break_up_item['@ondc/org/item_id'])
            const item = itemIndex > -1 ? items[itemIndex] : null
            // let itemQuantity = item ? item?.quantity?.count : 0
            // let quantity = break_up_item['@ondc/org/item_quantity']
            //   ? break_up_item['@ondc/org/item_quantity']['count']
            //   : 0
            // let textClass = ''
            // let quantityMessage = ''
            // if (quantity !== itemQuantity) {
            //   textClass = break_up_item['@ondc/org/title_type'] === 'item' ? 'text-amber' : ''
            //   quantityMessage = `Quantity: ${quantity}/${itemQuantity}`
            //   if (item) {
            //     item.quantity.count = quantity
            //   }
            // } else {
            //   quantityMessage = `Quantity: ${quantity}`
            // }

            uuid = uuid + 1
            return {
              id: break_up_item['@ondc/org/item_id'],
              title: break_up_item?.title,
              title_type: break_up_item['@ondc/org/title_type'],
              isCustomization: isItemCustomization(break_up_item?.item?.tags),
              isDelivery: break_up_item['@ondc/org/title_type'] === 'delivery',
              parent_item_id: break_up_item?.item?.parent_item_id,
              price: Number(break_up_item.price?.value)?.toFixed(2),
              // itemQuantity,
              // quantity,
              // provided_by,
              // textClass,
              // quantityMessage,
              uuid: uuid,
              fulfillment_status: item?.fulfillment_status,
              cancellation_status: item?.cancellation_status,
              return_status: item?.return_status,
              isCancellable: item?.product?.['@ondc/org/cancellable'],
              isReturnable: item?.product?.['@ondc/org/returnable'],
            }
          })
          let items = {}
          let delivery = {}
          let selected_fulfillment_id = orderDetails?.items[0]?.fulfillment_id
          all_items?.forEach((item) => {
            setQuoteItemInProcessing(item.id)
            // for type item
            if (item.title_type === 'item' && !item.isCustomization) {
              let key = item.parent_item_id || item.id
              let price = {
                title: item.quantity + ' X Base Price',
                value: item.price,
              }
              let prev_item_data = items[key]
              let addition_item_data = { title: item.title, price: price }
              addition_item_data.isCancellable = item.isCancellable
              addition_item_data.isReturnable = item.isReturnable
              addition_item_data.fulfillment_status = item?.fulfillment_status
              if (item?.return_status) {
                addition_item_data.return_status = item?.return_status
              }

              if (item?.cancellation_status) {
                addition_item_data.cancellation_status = item?.cancellation_status
              }

              items[key] = { ...prev_item_data, ...addition_item_data }
            }

            if (item.title_type === 'tax' && !item.isCustomization && item.id !== selected_fulfillment_id) {
              let key = item.parent_item_id || item.id
              items[key] = items[key] || {}
              items[key]['tax'] = {
                title: item.title,
                value: item.price,
              }
            }

            if (item.title_type === 'discount' && !item.isCustomization) {
              let key = item.parent_item_id || item.id
              items[key] = items[key] || {}
              items[key]['discount'] = {
                title: item.title,
                value: item.price,
              }
            }

            //for customizations
            if (item.title_type === 'item' && item.isCustomization) {
              let key = item.parent_item_id
              items[key]['customizations'] = items[key]['customizations'] || {}
              let existing_data = items[key]['customizations'][item.id] || {}
              let customisation_details = {
                title: item.title,
                price: {
                  title: item.quantity + ' X Base Price',
                  value: item.price,
                },
                quantityMessage: item.quantityMessage,
                textClass: item.textClass,
                quantity: item.quantity,
                cartQuantity: item.cartQuantity,
              }
              items[key]['customizations'][item.id] = {
                ...existing_data,
                ...customisation_details,
              }
            }

            if (item.title_type === 'tax' && item.isCustomization) {
              let key = item.parent_item_id
              items[key]['customizations'] = items[key]['customizations'] || {}
              items[key]['customizations'][item.id] = items[key]['customizations'][item.id] || {}
              items[key]['customizations'][item.id]['tax'] = {
                title: item.title,
                value: item.price,
              }
            }

            if (item.title_type === 'discount' && item.isCustomization) {
              let key = item.parent_item_id
              items[key]['customizations'] = items[key]['customizations'] || {}
              items[key]['customizations'][item.id] = items[key]['customizations'][item.id] || {}
              items[key]['customizations'][item.id]['discount'] = {
                title: item.title,
                value: item.price,
              }
            }

            //for delivery
            if (item.title_type === 'delivery') {
              delivery['delivery'] = {
                title: item.title,
                value: item.price,
              }
            }

            if (item.title_type === 'discount_f') {
              delivery['discount'] = {
                title: item.title,
                value: item.price,
              }
            }

            if ((item.title_type === 'tax_f' || item.title_type === 'tax') && item.id === selected_fulfillment_id) {
              delivery['tax'] = {
                title: item.title,
                value: item.price,
              }
            }

            if (item.title_type === 'packing') {
              delivery['packing'] = {
                title: item.title,
                value: item.price,
              }
            }

            if (item.title_type === 'discount') {
              if (item.isCustomization) {
                let id = item.parent_item_id
                id
              } else {
                let id = item.id
                items[id]['discount'] = {
                  title: item.title,
                  value: item.price,
                }
              }
            }

            if (item.title_type === 'misc') {
              delivery['misc'] = {
                title: item.title,
                value: item.price,
              }
            }
          })
          setQuoteItemInProcessing(null)
          setItemQuotes(items)
          // setDeliveryQuotes(delivery)
        }

        if (orderDetails.items && orderDetails.items.length > 0) {
          const filterCancelledItems = orderDetails.items.filter(
            (item) => item.cancellation_status && item.cancellation_status === 'Cancelled',
          )
          // const filterReturnItems = orderDetails.items.filter(
          //   (item) => item.cancellation_status && item.cancellation_status !== 'Cancelled',
          // )

          setCancelledItems(filterCancelledItems)
          // setReturnItems(filterReturnItems)
        }

        setReturnOrCancelledItems(getReturnOrCancelledItems())
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
      showQuoteError()
    }
  }, [orderDetails])

  useEffect(() => {
    if (orderDetails && itemQuotes) {
      const productsList = generateProductsList(orderDetails, itemQuotes)
      setProductsList(productsList)
    }
  }, [orderDetails, itemQuotes])

  useEffect(() => {
    if (productsList.length) {
      setAllNonCancellable(areAllItemsNonCancellable(productsList))
    }
  }, [productsList])

  useEffect(() => {
    if (orderDetails) {
      getTrackIssueDetails()
    }
  }, [orderDetails])

  // get issue status
  async function getTrackIssueDetails() {
    try {
      setIssueLoading(true)
      const data = await cancellablePromise(
        getSelectCall(`/issueApis/v1/issue?transactionId=${orderDetails?.transactionId}`),
      )
      setIssueData(data)
      // setIssueStatus(data?.allClosed)

      const { issueExistance } = data
      if (issueExistance) {
        setIssueLoading(false)
        setIsIssueRaised(true)
      } else {
        setIssueLoading(false)
      }
    } catch (err) {
      setIssueLoading(false)
      dispatchToast(err?.response?.data?.message, toast_types.error)
    }
  }

  const areAllItemsNonCancellable = (products) => {
    return products.some((obj) => !obj['@ondc/org/cancellable'])
  }

  function generateProductsList(orderDetails, itemQuotes) {
    const productList = orderDetails?.items
      ?.map(({ id }, index) => {
        let findQuote = orderDetails?.updatedQuote?.breakup.find(
          (item) => item['@ondc/org/item_id'] === id && item['@ondc/org/title_type'] === 'item',
        )
        if (findQuote) {
          if (findQuote?.item?.tags) {
            const tag = findQuote.item.tags.find((tag) => tag.code === 'type')
            const tagList = tag?.list
            const type = tagList?.find((item) => item.code === 'type')
            if (type?.value === 'item') {
              const parentId = findQuote?.item?.parent_item_id
              let customizations = null
              if (parentId) {
                customizations = itemQuotes[parentId].customizations
              }

              return {
                id,
                name: findQuote?.title ?? 'NA',
                cancellation_status: orderDetails.items?.[index]?.cancellation_status ?? '',
                return_status: orderDetails.items?.[index]?.return_status ?? '',
                fulfillment_status: orderDetails.items?.[index]?.fulfillment_status ?? '',
                customizations: customizations ?? null,
                ...orderDetails.items?.[index]?.product,
                parent_item_id: parentId,
                provider_details: orderDetails.provider,
              }
            }
          } else {
            const parentId = findQuote?.item?.parent_item_id
            return {
              id,
              name: findQuote?.title ?? 'NA',
              cancellation_status: orderDetails.items?.[index]?.cancellation_status ?? '',
              return_status: orderDetails.items?.[index]?.return_status ?? '',
              fulfillment_status: orderDetails.items?.[index]?.fulfillment_status ?? '',
              customizations: null,
              ...orderDetails.items?.[index]?.product,
              parent_item_id: parentId,
              provider_details: orderDetails.provider,
            }
          }
        } else {
          findQuote = orderDetails.updatedQuote?.breakup[index]
        }

        return null
      })
      .filter((item) => item !== null)
    return productList
  }

  // function to dispatch error
  function dispatchError(message) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: toast_types.error,
        message,
      },
    })
  }

  const showQuoteError = () => {
    let msg = ''
    if (quoteItemInProcessing) {
      msg = `Looks like Quote mapping for item: ${quoteItemInProcessing} is invalid! Please check!`
    } else {
      msg = 'Seems like issue with quote processing! Please confirm first if quote is valid!'
    }

    dispatchError(msg)
  }

  const getSubTotal = (quote) => {
    let subtotal = 0
    quote?.map((item) => {
      subtotal += parseFloat(item?.price?.value)
    })
    return subtotal
  }

  getSubTotal()

  const getItemsWithCustomizations = () => {
    const breakup = orderDetails?.updatedQuote?.breakup
    let returnBreakup = []
    const filterItems = breakup?.filter((item) => item['@ondc/org/title_type'] === 'item')
    const filterCustomizations = breakup?.filter((item) => item['@ondc/org/title_type'] === 'customization')
    filterItems?.forEach((item) => {
      const itemId = item['@ondc/org/item_id']
      const filterCustomizationItems = filterCustomizations?.filter((cust) => cust.item.parent_item_id === itemId)
      returnBreakup.push(item)
      if (filterCustomizationItems?.length > 0) {
        filterCustomizationItems?.forEach((custItem) => {
          returnBreakup.push(custItem)
        })
      }
    })
    return returnBreakup
  }

  getItemsWithCustomizations()

  const fulfillmentStatus = [
    'Pending',
    'Packed',
    'Agent-assigned',
    'Out-for-pickup',
    'Pickup-failed',
    'In-transit',
    'At-destination-hub',
    'Out-for-delivery',
    'Order-picked-up',
    'Delivery-failed',
    'Order-delivered',
    'Cancelled',
  ]

  const renderItems = () => {
    try {
      return (
        <div>
          {Object.values(orderDetails?.items || [])
            .filter((item) => {
              const isCancelled = item.fulfillment_status === 'Cancelled'
              return (
                fulfillmentStatus.includes(item.fulfillment_status) &&
                (!isCancelled || item?.quantity?.count > 0) &&
                (item.fulfillment_status !== 'Agent-assigned' || item?.quantity?.count > 0)
              )
            })
            .map((item, qIndex) => {
              const imgLink = item?.product?.descriptor?.images?.[0]
              return (
                <div key={`quote-${qIndex}`}>
                  <div className={classes.summaryQuoteItemContainer} key={`quote-${qIndex}-title`}>
                    <Typography variant="body1" className={`${classes.summaryItemLabel}`}>
                      <Wrapper>
                        <ProductWrapper>
                          <ProductImage>
                            <img
                              src={
                                imgLink ||
                                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                              }
                              alt={item?.product?.descriptor?.name}
                              onError={(e) => {
                                e.target.src =
                                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                              }}
                            />
                          </ProductImage>
                          <div>
                            {item?.product?.descriptor?.name}
                            <div>
                              <PaymentValue>
                                {`₹${formatIndianRupees(parseFloat(item?.product?.price?.value).toFixed(2))}`}
                              </PaymentValue>
                            </div>
                          </div>
                        </ProductWrapper>
                        {location.pathname !== `/track-order/${orderDetails?.id}` && item?.fulfillment_status && (
                          <Chip
                            size="small"
                            className={`${classes.statusChip} ${
                              item.fulfillment_status === 'Pending'
                                ? classes.pending
                                : item.fulfillment_status === 'Agent-assigned'
                                  ? classes.agentAssigned
                                  : item.fulfillment_status === 'Order-picked-up'
                                    ? classes.orderPickedUp
                                    : item.fulfillment_status === 'Out-for-delivery'
                                      ? classes.outForDelivery
                                      : item.fulfillment_status === 'Order-delivered'
                                        ? classes.orderDelivered
                                        : item.fulfillment_status === 'Cancelled'
                                          ? classes.cancelled
                                          : ''
                            }`}
                            label={item?.fulfillment_status}
                          />
                        )}
                      </Wrapper>
                    </Typography>
                  </div>

                  <div className={`${classes.summaryQuoteItemContainer} ${classes.marginBottom12}`}>
                    <>
                      <Chip
                        size="small"
                        className={classes.tagsChip}
                        label={item?.product['@ondc/org/returnable'] ? 'returnable' : 'non returnable'}
                      />
                      <Chip
                        size="small"
                        className={classes.tagsChip}
                        label={item?.product['@ondc/org/cancellable'] ? 'cancelable' : 'non cancelable'}
                      />
                    </>
                  </div>
                  {item?.product?.price?.value !== '0.00' && renderItemDetails(item)}
                </div>
              )
            })}
        </div>
      )
    } catch (error) {
      return error
    }
  }

  const renderCancelledItems = () => {
    return (
      <CancelWrapper>
        <ReturnHeading> Cancelled Items</ReturnHeading>

        {cancelledItems?.map((item, index) => {
          let imgLink = item?.product?.descriptor?.images[0]

          return (
            <ReturnItemsWrapper key={index}>
              <ItemContentWrapper>
                <ProductImage>
                  <img
                    src={
                      imgLink ||
                      'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                    }
                    alt={item?.title}
                    onError={(e) => {
                      e.target.src =
                        'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                    }}
                  />
                </ProductImage>
                <ItemTitle>
                  <Typography variant="body1" className={classes.summaryItemPriceLabel}>
                    {item?.product?.descriptor?.name}
                  </Typography>
                  <ItemQuantity>
                    <PaymentTitle> Qty:</PaymentTitle>
                    <PaymentValue> {item?.quantity?.count}</PaymentValue>
                  </ItemQuantity>
                </ItemTitle>
              </ItemContentWrapper>
              <ItemStatusWrapper>
                {item?.fulfillment_status && (
                  <Chip
                    key={item.id}
                    size="small"
                    className={`${classes.statusChip} `}
                    label={item?.fulfillment_status}
                  />
                )}
              </ItemStatusWrapper>
            </ReturnItemsWrapper>
          )
        })}
      </CancelWrapper>
    )
  }

  const renderReturnItems = () => {
    return (
      <ReturnWrapper>
        <ReturnHeading> Returned Items</ReturnHeading>

        {returnOrCancelledItems?.map((item, index) => {
          let imgLink = item?.productDetails?.product?.descriptor?.images[0]
          return (
            <>
              <ReturnItemsWrapper key={index}>
                <ItemContentWrapper>
                  <ProductImage>
                    <img
                      src={
                        imgLink ||
                        'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }
                      alt={item?.title}
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                    />
                  </ProductImage>
                  <ItemTitle>
                    <Typography variant="body1" className={classes.summaryItemPriceLabel}>
                      {item?.productDetails?.product?.descriptor?.name}
                    </Typography>
                    <ItemQuantity>
                      <PaymentTitle> Qty:</PaymentTitle>
                      <PaymentValue> {item?.quantity}</PaymentValue>
                    </ItemQuantity>
                  </ItemTitle>
                </ItemContentWrapper>
                <ItemStatusWrapper>
                  {' '}
                  {item?.status && (
                    <Chip key={item.id} size="small" className={`${classes.statusChip} `} label={item?.status} />
                  )}
                </ItemStatusWrapper>
              </ReturnItemsWrapper>
              {item?.refundAmount && (
                <ReturnItemsWrapper>
                  <RefundedAmountValue> Refunded Amount</RefundedAmountValue>
                  <RefundedAmountValue>₹{formatIndianRupees(item?.refundAmount?.toFixed(2))}</RefundedAmountValue>
                </ReturnItemsWrapper>
              )}
            </>
          )
        })}
      </ReturnWrapper>
    )
  }

  // eslint-disable-next-line no-unused-vars
  const renderItemDetails = (quote, qIndex, isCustomization) => {
    return (
      <div>
        <div className={classes.summaryQuoteItemContainer} key={`quote-${qIndex}-price`}>
          <Typography
            variant="body1"
            className={isCustomization ? classes.summaryCustomizationPriceLabel : classes.summaryItemPriceLabel}
          >
            {quote?.boughtItemcount} X Base Price
            {/* {quote?.product?.price?.value} */}
          </Typography>
          <Typography
            variant="body1"
            className={isCustomization ? classes.summaryCustomizationPriceValue : classes.summaryItemPriceValue}
          >
            {`₹${formatIndianRupees(parseFloat(quote?.boughtItemcount * quote?.product?.price?.value).toFixed(2))}`}
          </Typography>
        </div>
      </div>
    )
  }

  const getItemsTotal = () => {
    let finalTotal = 0
    if (itemQuotes) {
      const items = Object.values(itemQuotes).filter((quote) => quote?.title !== '')
      items?.forEach((item) => {
        finalTotal = finalTotal + parseFloat(item?.price?.value)
        if (item?.tax) {
          finalTotal = finalTotal + parseFloat(item?.tax?.value)
        }

        if (item.customizations) {
          Object.values(item.customizations)?.forEach((custItem) => {
            finalTotal = finalTotal + parseFloat(custItem?.price?.value)
            if (custItem?.tax) {
              finalTotal = finalTotal + parseFloat(custItem?.tax?.value)
            }
          })
        }
      })
    }

    finalTotal = parseFloat(finalTotal).toFixed(2)
    return finalTotal
  }
  const totalAmount = getItemsTotal()

  const [getItemTotalValueCharges, setItemTotalValueCharges] = useState()
  // const [getTotalCharges, setTotalCharges] = useState()
  const [getDeliveryCharges, setDeliveryCharges] = useState()
  const [getTaxCharges, setTaxCharges] = useState()
  const [getPackingCharges, setPackingCharges] = useState()
  const [getMiscCharges, setMiscCharges] = useState()
  const [getDiscount, setGetDiscount] = useState()

  useEffect(() => {
    if (orderDetails) {
      let totalValue = 0

      // Iterate through the breakup array
      orderDetails?.quote?.breakup?.forEach((item) => {
        if (item['@ondc/org/title_type'] === 'item') {
          const priceValue = parseFloat(item?.item?.price?.value)
          const itemCount = item['@ondc/org/item_quantity']?.count
          const itemTotalValue = priceValue * itemCount
          totalValue += itemTotalValue
        }
      })

      setItemTotalValueCharges(totalValue)

      // const applicableTitleTypes = ['delivery', 'tax', 'packing', 'discount', 'misc']
      // const sumValues = orderDetails?.quote?.breakup?.reduce((sumB, itemB) => {
      //   const titleType = itemB['@ondc/org/title_type']
      //   if (applicableTitleTypes?.includes(titleType)) {
      //     const value = parseFloat(itemB?.price?.value)
      //     return sumB + value
      //   }

      //   return sumB
      // }, 0)
      // setTotalCharges(Math.abs(sumValues))

      const deliveryCharges = orderDetails.quote.breakup.reduce((sumC, itemC) => {
        if (itemC['@ondc/org/title_type'] === 'delivery') {
          return sumC + parseFloat(itemC.price.value)
        }

        return sumC
      }, 0)
      setDeliveryCharges(deliveryCharges)

      const taxCharges = orderDetails.quote.breakup.reduce((sumD, itemD) => {
        if (itemD['@ondc/org/title_type'] === 'tax') {
          return sumD + parseFloat(itemD.price.value)
        }

        return sumD
      }, 0)
      setTaxCharges(taxCharges)

      const packingCharges = orderDetails.quote.breakup.reduce((sumE, itemE) => {
        if (itemE['@ondc/org/title_type'] === 'packing') {
          return sumE + parseFloat(itemE.price.value)
        }

        return sumE
      }, 0)
      setPackingCharges(packingCharges)

      const miscCharges = orderDetails.quote.breakup.reduce((sumF, itemF) => {
        if (itemF['@ondc/org/title_type'] === 'misc') {
          return sumF + parseFloat(itemF.price.value)
        }

        return sumF
      }, 0)

      setMiscCharges(miscCharges)
      const discountValue = orderDetails.quote.breakup.find((item) => item['@ondc/org/title_type'] === 'discount')

      setGetDiscount(discountValue ? parseFloat(Math.abs(discountValue.price.value)) : 0)

      // setGetDiscount(discountItems)
    }
  }, [orderDetails])

  const renderQuote = () => {
    try {
      return (
        <div>
          <div>{renderItems()}</div>
          {/* <div>{itemQuotes ? renderItems() : ''}</div> */}

          <OrderPaymentWrapper>
            <PaymentHeading> Order Payment Details</PaymentHeading>
            <PaymentWrapper>
              <PaymentTitle> Item(s) Subtotal</PaymentTitle>
              <PaymentValue>₹{formatIndianRupees(parseFloat(getItemTotalValueCharges).toFixed(2))}</PaymentValue>
            </PaymentWrapper>

            {getDeliveryCharges > 0 && (
              <PaymentWrapper>
                <PaymentTitle> Delivery Charges</PaymentTitle>
                <PaymentValue>₹{formatIndianRupees(getDeliveryCharges)}</PaymentValue>
              </PaymentWrapper>
            )}

            {getTaxCharges > 0 && (
              <PaymentWrapper>
                <PaymentTitle> Tax</PaymentTitle>
                <PaymentValue>₹{formatIndianRupees(getTaxCharges)}</PaymentValue>
              </PaymentWrapper>
            )}

            {getPackingCharges > 0 && (
              <PaymentWrapper>
                <PaymentTitle> Packing Charges</PaymentTitle>
                <PaymentValue>₹{formatIndianRupees(getPackingCharges)}</PaymentValue>
              </PaymentWrapper>
            )}

            {getMiscCharges > 0 && (
              <PaymentWrapper>
                <PaymentTitle> Convenience Fee</PaymentTitle>
                <PaymentValue>{formatIndianRupees(getMiscCharges)}</PaymentValue>
              </PaymentWrapper>
            )}
            {getDiscount > 0 && (
              <PaymentWrapper>
                <PaymentTitle> Discount</PaymentTitle>
                <PaymentValue>
                  {getDiscount ? '-' : ''}
                  {formatIndianRupees(getDiscount)}
                </PaymentValue>
              </PaymentWrapper>
            )}

            {/* {deliveryQuotes ? renderDeliveryCharges(deliveryQuotes) : ''} */}
            {/* <PaymentWrapper>
              <PaymentTitle> Total Charges</PaymentTitle>

              <PaymentValue>₹{formatIndianRupees(getTotalCharges)}</PaymentValue>
            </PaymentWrapper> */}

            <PaymentWrapper>
              <Typography variant="body" className={classes.totalLabel}>
                Order Total
              </Typography>

              <TotalValue> ₹{formatIndianRupees(parseFloat(orderDetails?.quote?.price?.value).toFixed(2))}</TotalValue>
            </PaymentWrapper>
          </OrderPaymentWrapper>

          {cancelledItems?.length > 0 ? renderCancelledItems() : ''}
          {returnOrCancelledItems?.length > 0 ? renderReturnItems() : ''}
          {orderDetails?.refunded_amount !== 0 && <Box component={'div'} className={classes.orderSummaryDivider} />}

          {orderDetails && orderDetails?.refunded_amount !== 0 && (
            <PaymentWrapper>
              <Typography variant="body" className={classes.totalLabel}>
                Total Refund Amount
              </Typography>
              <TotalValue> {`₹${formatIndianRupees(parseFloat(orderDetails?.refunded_amount).toFixed(2))}`}</TotalValue>
            </PaymentWrapper>
          )}
        </div>
      )
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error?.response?.data?.error?.message,
        },
      })
      showQuoteError()
    }
  }

  // on status
  async function getUpdatedStatus(message_id) {
    try {
      const data = await cancellablePromise(getSelectCall(`/clientApis/v2/on_order_status?messageIds=${message_id}`))
      statusEventSourceResponseRef.current = [...statusEventSourceResponseRef.current, data[0]]
      const { message, error = {} } = data[0]
      if (error?.message) {
        dispatchToast('Cannot get status for this product', toast_types.error)
        setStatusLoading(false)
        return
      }

      if (message?.order) {
        onUpdateOrder(message?.order)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Order status updated successfully!',
          },
        })
      }

      setStatusLoading(false)
    } catch (err) {
      setStatusLoading(false)
      dispatchToast(err?.message, toast_types.error)
      eventTimeOutRef.current?.forEach(({ eventSource, timer }) => {
        eventSource.close()
        clearTimeout(timer)
      })
    }
  }

  // STATUS APIS
  // use this function to fetch support info through events
  function fetchStatusDataThroughEvents(message_id) {
    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }
    let es = new window.EventSourcePolyfill(
      `${process.env.REACT_APP_BASE_URL}clientApis/events?messageId=${message_id}`,
      header,
    )
    es.addEventListener('on_status', (e) => {
      const { messageId } = JSON.parse(e?.data)
      getUpdatedStatus(messageId)
    })

    const timer = setTimeout(() => {
      es.close()
      if (statusEventSourceResponseRef.current?.length <= 0) {
        dispatchToast('We are facing some technical issue. Please try again', toast_types.warning)
        setStatusLoading(false)
      }
    }, SSE_TIMEOUT)

    eventTimeOutRef.current = [
      ...eventTimeOutRef.current,
      {
        eventSource: es,
        timer,
      },
    ]
  }

  // use this api to get updated status of the order
  async function handleFetchUpdatedStatus() {
    statusEventSourceResponseRef.current = []
    setStatusLoading(true)
    const transaction_id = orderDetails?.transactionId
    const bpp_id = orderDetails?.bppId
    const order_id = orderDetails?.id
    try {
      const data = await cancellablePromise(
        postCheckoutCall('/clientApis/v2/order_status', [
          {
            context: {
              transaction_id,
              bpp_id,
            },
            message: {
              order_id,
            },
          },
        ]),
      )
      //Error handling workflow eg, NACK
      if (data[0].error && data[0].message.ack.status === 'NACK') {
        setStatusLoading(false)
        dispatchToast(data[0].error.message, toast_types.error)
      } else {
        fetchStatusDataThroughEvents(data[0]?.context?.message_id)
      }
    } catch (err) {
      setStatusLoading(false)
      dispatchToast(err?.response?.data[0]?.message, toast_types.error)
    }
  }

  // useEffect(() => {
  //   if (orderDetails) {
  //     handleFetchUpdatedStatus()
  //   }
  // }, [])

  // TRACK APIS
  // use this api to track and order
  async function handleFetchTrackOrderDetails() {
    history.push(`/track-order/${orderDetails?.id}`)
    trackEventSourceResponseRef.current = []
    setTrackOrderLoading(true)
    const transaction_id = orderDetails?.transactionId
    const bpp_id = orderDetails?.bppId
    const order_id = orderDetails?.id
    try {
      const data = await cancellablePromise(
        postCheckoutCall('/clientApis/v2/track', [
          {
            context: {
              transaction_id,
              bpp_id,
            },
            message: {
              order_id,
            },
          },
        ]),
      )
      fetchTrackingDataThroughEvents(data[0]?.context?.message_id)
    } catch (err) {
      setTrackOrderLoading(false)
      dispatchToast(err?.response?.data?.message, toast_types.error)
    }
  }

  // use this function to fetch tracking info through events
  function fetchTrackingDataThroughEvents(message_id) {
    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }
    let es = new window.EventSourcePolyfill(
      `${process.env.REACT_APP_BASE_URL}clientApis/events?messageId=${message_id}`,
      header,
    )
    es.addEventListener('on_track', (e) => {
      const { messageId } = JSON.parse(e?.data)
      getTrackOrderDetails(messageId)
    })

    const timer = setTimeout(() => {
      es.close()
      if (trackEventSourceResponseRef.current?.length <= 0) {
        dispatchToast(
          'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
          toast_types.error,
        )
        setTrackOrderLoading(false)
      }
    }, SSE_TIMEOUT)

    eventTimeOutRef.current = [
      ...eventTimeOutRef.current,
      {
        eventSource: es,
        timer,
      },
    ]
  }

  // on track order
  async function getTrackOrderDetails(message_id) {
    try {
      const data = await cancellablePromise(getSelectCall(`/clientApis/v2/on_track?messageIds=${message_id}`))
      setTrackOrderLoading(false)
      trackEventSourceResponseRef.current = [...trackEventSourceResponseRef.current, data[0]]
      const { message } = data[0]
      if (message.tracking.status === 'active' && message.tracking.url === '') {
        onUpdateTrakingDetails(null)
        setTrackOrderLoading(false)
        dispatchToast('Tracking information is not provided by the provider.', toast_types.error)
        return
      } else if (message?.tracking?.url === '') {
        onUpdateTrakingDetails(null)
        setTrackOrderLoading(false)
        dispatchToast('Tracking information not available for this product', toast_types.error)
        return
      } else if (
        message.tracking.status === 'active' &&
        (message?.tracking?.url !== '' || message?.tracking?.url !== undefined)
      ) {
        setTrackOrderLoading(false)
        trackOrderRef.current.href = message?.tracking?.url
        trackOrderRef.current.target = '_blank'
        trackOrderRef.current.click()
        onUpdateTrakingDetails(null)
      } else if (message.tracking.status === 'active' && message?.tracking?.location?.gps) {
        onUpdateTrakingDetails(message?.tracking)
      } else {
        onUpdateTrakingDetails(null)
        setTrackOrderLoading(false)
        dispatchToast('Tracking information is not provided by the provider.', toast_types.error)
        return
      }
    } catch (err) {
      setTrackOrderLoading(false)
      dispatchToast(err?.message, toast_types.error)
      eventTimeOutRef.current?.forEach(({ eventSource, timer }) => {
        eventSource.close()
        clearTimeout(timer)
      })
    }
  }

  const orderItems = (orderDetails, itemQuotes) => {
    return {
      quantity: orderDetails.items
        ?.filter(
          (item) =>
            !Object.prototype.hasOwnProperty.call(item, 'cancellation_status') ||
            (Object.prototype.hasOwnProperty.call(item, 'cancellation_status') && item.cancellation_status == '') ||
            !Object.prototype.hasOwnProperty.call(item, 'return_status') ||
            (Object.prototype.hasOwnProperty.call(item, 'return_status') && item.return_status == ''),
        )
        .map(({ quantity }) => quantity),
      partailsCancelProductList: generateProductsList(orderDetails, itemQuotes).filter((item) => {
        if (orderDetails?.domain === 'ONDC:RET11') {
          return (
            orderDetails?.state === 'Created' &&
            item['@ondc/org/cancellable'] == true &&
            item.fulfillment_status == 'Pending' &&
            (!Object.prototype.hasOwnProperty.call(item, 'cancellation_status') ||
              (Object.prototype.hasOwnProperty.call(item, 'cancellation_status') && item.cancellation_status == '') ||
              !Object.prototype.hasOwnProperty.call(item, 'return_status') ||
              (Object.prototype.hasOwnProperty.call(item, 'return_status') && item.return_status == ''))
          )
        } else {
          return (
            (orderDetails.state === 'Accepted' || orderDetails.state === 'Created') &&
            item['@ondc/org/cancellable'] == true &&
            item.fulfillment_status == 'Pending' &&
            (!Object.prototype.hasOwnProperty.call(item, 'cancellation_status') ||
              (Object.prototype.hasOwnProperty.call(item, 'cancellation_status') && item.cancellation_status == '') ||
              !Object.prototype.hasOwnProperty.call(item, 'return_status') ||
              (Object.prototype.hasOwnProperty.call(item, 'return_status') && item.return_status == ''))
          )
        }
      }),
    }
  }

  const filterItems = (items, fulfillments) => {
    return items
      ?.filter(
        (item) =>
          (!Object.prototype.hasOwnProperty.call(item, 'cancellation_status') ||
            (Object.prototype.hasOwnProperty.call(item, 'cancellation_status') && item.cancellation_status === '')) &&
          (!Object.prototype.hasOwnProperty.call(item, 'return_status') ||
            (Object.prototype.hasOwnProperty.call(item, 'return_status') && item.return_status === '')),
      )
      .map((item) => {
        // Check if there is a return initiated for this item
        const returnInitiated = fulfillments.some(
          (fulfillment) =>
            fulfillment.type === 'Return' &&
            fulfillment.tags.some(
              (tag) =>
                tag.code === 'return_request' && tag.list.some((r) => r.code === 'item_id' && r.value === item.id),
            ),
        )

        if (returnInitiated) {
          // If return initiated, adjust the quantity accordingly
          const returnQuantity = fulfillments
            .filter(
              (fulfillment) =>
                fulfillment.type === 'Return' &&
                fulfillment.tags.some(
                  (tag) =>
                    tag.code === 'return_request' && tag.list.some((r) => r.code === 'item_id' && r.value === item.id),
                ),
            )
            .map(
              (fulfillment) =>
                fulfillment.tags
                  .find((tag) => tag.code === 'return_request' && tag.list.some((r) => r.code === 'item_quantity'))
                  .list.find((r) => r.code === 'item_quantity').value,
            )
            .reduce((acc, curr) => acc + parseInt(curr) || 0, 0) // Ensure returnQuantity is a number

          const updatedQuantity = (parseInt(item.boughtItemcount) || 0) - returnQuantity // Ensure item.quantity.count is a number
          return { ...item, quantity: { ...item.quantity, count: updatedQuantity } }
        }

        return { ...item }
      })
      .map(({ quantity }) => parseInt(quantity.count) || 0) // Ensure the returned value is a number
  }

  const filterPartialReturnProductList = () =>
    generateProductsList(orderDetails, itemQuotes).filter((item) => {
      if (
        !Object.prototype.hasOwnProperty.call(item, 'cancellation_status') ||
        (Object.prototype.hasOwnProperty.call(item, 'cancellation_status') && item.cancellation_status == '') ||
        !Object.prototype.hasOwnProperty.call(item, 'return_status') ||
        (Object.prototype.hasOwnProperty.call(item, 'return_status') && item.return_status == '')
      ) {
        return item
      }
    })

  const getReturnOrCancelledItems = () => {
    try {
      let items = []
      orderDetails?.fulfillments?.forEach((f) => {
        if (f.type === 'Return') {
          const details = f.tags[0].list
          const returnedItemId = details.find((d) => d.code === 'item_id')?.value
          const returnedItemQuantity = details.find((d) => d.code === 'item_quantity')?.value
          const productDetails = orderDetails?.items?.find((item) => item.id === returnedItemId)

          const itemData = {
            id: returnedItemId,
            quantity: returnedItemQuantity,
            type: f.type,
            status: f.state.descriptor.code,
            productDetails: productDetails,
          }

          if (f.tags[1]) {
            const amountDetails = f.tags[1].list
            const refundAmountValue = amountDetails.find((d) => d.code === 'value')?.value
            const refundedAmount = refundAmountValue ? Math.abs(refundAmountValue) : undefined

            if (refundedAmount !== undefined) {
              itemData.refundAmount = refundedAmount
            }
          }

          items.push(itemData)
        }
      })

      return items
    } catch (error) {
      return error
    }
  }

  const issueModal = () => {
    setToggleIssueModal(true)
  }

  const returnOrderModal = () => {
    setToggleReturnOrderModal(true)
  }

  const openCancelOrderModal = () => {
    setToggleCancelOrderModal(true)
  }

  const issueOrderId = orderDetails?.id
  const handleTrackIssue = () => {
    history.push(`/profile/complaints/${issueOrderId}`)
    // history.push(`/profile/complaints/${newOrderIssueId}`, {
    //   data: { transaction_id: transactionId, context, issue_actions, issueId },
    // })
    setIsIssueRaised(true)
    setIssueLoading(true)
  }

  const isOrderCompleted = orderDetails?.state === 'Completed'

  function isReturnButtonDisabled(orderDetails) {
    const areAllItemsNotReturnable = orderDetails?.items?.every((item) => {
      return item?.product?.['@ondc/org/returnable'] === false
    })

    if (areAllItemsNotReturnable) return true

    const { items, fulfillments } = orderDetails
    // Map to store the returned quantities for each item
    const returnedQuantities = {}

    fulfillments.forEach((fulfillment) => {
      if (fulfillment.type === 'Return') {
        fulfillment.tags.forEach((tag) => {
          if (tag.code === 'return_request') {
            const itemId = tag.list.find((detail) => detail.code === 'item_id')?.value
            const itemQuantity = Number(tag.list.find((detail) => detail.code === 'item_quantity')?.value)

            if (itemId && !isNaN(itemQuantity)) {
              if (!returnedQuantities[itemId]) {
                returnedQuantities[itemId] = 0
              }

              returnedQuantities[itemId] += itemQuantity
            }
          }
        })
      }
    })

    for (let item of items) {
      if (item.fulfillment_status === 'Order-delivered' && item?.product?.['@ondc/org/returnable'] === true) {
        const itemId = item.id
        const orderedQuantity = item.boughtItemcount

        const returnedQuantity = returnedQuantities[itemId] || 0

        if (returnedQuantity < orderedQuantity) {
          return false
        }
      }
    }

    return true
  }

  return (
    <div>
      <Card className={classes.orderSummaryCard}>
        <Typography variant="h4" className={classes.orderSummaryHeading}>
          Order Summary
        </Typography>
        <div style={{ padding: '20px' }}>
          {/* <HeadingWrapper> */}
          <StatusWrapper>
            {location.pathname !== `/track-order/${orderDetails?.id}` && !(orderDetails?.state === 'Cancelled') && (
              <div onClick={() => handleFetchUpdatedStatus()}>{statusLoading ? <Loading /> : <GetStatusIcon />}</div>
            )}

            {location.pathname !== `/track-order/${orderDetails?.id}` && (
              <Chip
                className={classes.statusChip}
                color={
                  orderDetails?.state === 'Confirmed' || orderDetails?.state === 'Created'
                    ? 'primary'
                    : orderDetails?.state === 'Delivered'
                      ? 'success'
                      : orderDetails?.state === 'Cancelled'
                        ? 'error'
                        : 'primary'
                }
                label={orderDetails?.state}
              />
            )}
          </StatusWrapper>
          <InfoSection>
            <ProductDetailSection>
              <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                Order Number
              </Typography>
              <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                {orderDetails?.id}
              </Typography>
            </ProductDetailSection>
            <ProductDetailSection>
              <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                Ordered On
              </Typography>
              <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                {moment(orderDetails?.createdAt).format('MMMM Do, YYYY')} at{' '}
                <span>{moment(orderDetails?.createdAt).format('hh:mma')}</span>
              </Typography>
            </ProductDetailSection>
            <ProductDetailSection>
              <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                Payment Mode
              </Typography>
              <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                {orderDetails?.payment?.type === 'ON-FULFILLMENT' ? 'Cash on delivery' : 'Prepaid'}
              </Typography>
            </ProductDetailSection>
          </InfoSection>

          {renderQuote()}
          <div className={classes.summaryItemActionContainer}>
            {location.pathname !== `/track-order/${orderDetails?.id}` && (
              <ButtonWrapper>
                {(orderDetails?.state === 'Accepted' ||
                  orderDetails?.state === 'In-progress' ||
                  orderDetails?.state === 'Created' ||
                  orderDetails?.state === 'Completed') && (
                  <>
                    {
                      isIssueRaised ? (
                        <Button
                          fullWidth
                          variant="outlined"
                          className={classes.helpButton}
                          disabled={statusLoading || issueLoading}
                          onClick={handleTrackIssue}
                        >
                          {issueLoading ? <Loading /> : 'Track Issue'}
                        </Button>
                      ) : (
                        ''
                      )
                      //   (
                      //   <Button
                      //     fullWidth
                      //     variant="outlined"
                      //     className={classes.helpButton}
                      //     disabled={trackOrderLoading || statusLoading || issueLoading}
                      //     onClick={issueModal}
                      //   >
                      //     {issueLoading ? <Loading /> : 'Raise Issue'}
                      //   </Button>
                      // )
                    }
                  </>
                )}

                {(issueButton || orderDetails?.state === 'Completed') && orderDetails?.state !== 'Cancelled' ? (
                  <Button
                    fullWidth
                    variant="outlined"
                    className={classes.helpButton}
                    disabled={trackOrderLoading || statusLoading || issueLoading}
                    onClick={issueModal}
                  >
                    {issueLoading ? <Loading /> : 'Raise Issue'}
                  </Button>
                ) : (
                  ''
                )}

                {orderDetails?.state !== 'Cancelled' && (
                  <Button
                    fullWidth
                    variant="outlined"
                    className={`${classes.helpButton} `}
                    onClick={handleFetchTrackOrderDetails}
                    disabled={orderDetails?.state === 'Completed'}
                  >
                    Track
                  </Button>
                )}

                {(orderDetails?.state === 'Accepted' || orderDetails?.state === 'Created') && (
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.cancelOrderButton}
                    onClick={openCancelOrderModal}
                    disabled={allNonCancellable || statusLoading}
                  >
                    Cancel Order
                  </Button>
                )}
                {isOrderCompleted && (
                  <>
                    <Button
                      fullWidth
                      variant="contained"
                      className="return-order"
                      onClick={returnOrderModal}
                      disabled={isReturnButtonDisabled(orderDetails)}
                    >
                      Return Order
                    </Button>
                  </>
                )}
              </ButtonWrapper>
            )}
          </div>
        </div>
        {toggleReturnOrderModal && (
          <ModalComponent
            title="Return Order"
            titleBg={true}
            open={toggleReturnOrderModal}
            onClose={() => setToggleReturnOrderModal(false)}
          >
            <ReturnOrderModal
              onClose={() => setToggleReturnOrderModal(false)}
              onSuccess={() => {
                setToggleReturnOrderModal(false)
                onUpdateOrder()
              }}
              returnOrCancelledItems={returnOrCancelledItems}
              orderDetails={orderDetails}
              quantity={filterItems(orderDetails.items, orderDetails?.fulfillments)}
              partailsReturnProductList={filterPartialReturnProductList(orderDetails.items)}
              order_status={orderDetails.state}
              bpp_id={orderDetails.bppId}
              transaction_id={orderDetails.transactionId}
              order_id={orderDetails.id}
              domain={orderDetails.domain}
              bpp_uri={orderDetails.bpp_uri}
              handleFetchUpdatedStatus={handleFetchUpdatedStatus}
              onUpdateOrder={onUpdateOrder}
              fulfillments={orderDetails?.fulfillments}
            />
          </ModalComponent>
        )}

        {toggleCancelOrderModal && (
          <ModalComponent
            title="Cancel Order"
            titleBg={true}
            open={toggleCancelOrderModal}
            onClose={() => setToggleCancelOrderModal(false)}
          >
            <CancelOrderModal
              onClose={() => setToggleCancelOrderModal(false)}
              onSuccess={() => {
                setToggleCancelOrderModal(false)
                onUpdateOrder()
              }}
              quantity={orderItems(orderDetails, orderDetails?.items).quantity}
              order_status={orderDetails.state}
              bpp_id={orderDetails.bppId}
              transaction_id={orderDetails.transactionId}
              order_id={orderDetails.id}
              domain={orderDetails.domain}
              bpp_uri={orderDetails.bpp_uri}
              handleFetchUpdatedStatus={handleFetchUpdatedStatus}
              onUpdateOrder={onUpdateOrder}
            />
          </ModalComponent>
        )}

        {toggleIssueModal && (
          <ModalComponent
            title="Raise an Issue"
            titleBg={true}
            open={toggleIssueModal}
            onClose={() => setToggleIssueModal(false)}
          >
            <IssueOrderModal
              onClose={() => setToggleIssueModal(false)}
              onSuccess={() => {
                setToggleIssueModal(false)
                onUpdateOrder()
                dispatch({
                  type: toast_actions.ADD_TOAST,
                  payload: {
                    id: Math.floor(Math.random() * 100),
                    type: toast_types.success,
                    message: 'Complaint raised successfully!',
                  },
                })
              }}
              issueData={issueData}
              quantity={orderDetails.items?.map(({ boughtItemcount }) => boughtItemcount)}
              partailsIssueProductList={generateProductsList(orderDetails, itemQuotes)}
              order_status={orderDetails.state}
              billing_address={orderDetails?.billing}
              transaction_id={orderDetails.transactionId}
              order_id={orderDetails.id}
              bpp_id={orderDetails.bppId}
              bpp_uri={orderDetails.bpp_uri}
              fulfillments={orderDetails.fulfillments}
              domain={orderDetails.domain}
              amount={totalAmount}
            />
          </ModalComponent>
        )}
        <a ref={trackOrderRef} style={{ visibility: 'hidden', display: 'none' }}>
          navigate
        </a>
      </Card>
      {/* <br />
      {returnOrCancelledItems?.length > 0 && (
        <ReturnOrderProductsWrapper>
          <ReturnHeading> Returned / Cancelled Items</ReturnHeading>
          <div>{renderReturnOrCancel()}</div>
        </ReturnOrderProductsWrapper>
      )} */}
    </div>
  )
}

export default OrderSummary
