import React, { useContext, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import Slider from 'react-slick'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FormControlLabel, RadioGroup } from '@mui/material'
import Radio from '@mui/material/Radio'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import usePost from 'hooks/usePost'
import useGet from 'hooks/useGet'
import { getSelectCall, postCheckoutCall } from 'api/axios'
import useCancellablePromise from 'api/cancelRequest'
import { ADD_ADDRESS_VALIDATION } from 'utils/Validation/addressForm-validation'
import { AddCookie, getValueFromCookie } from 'utils/cookies'
import { getUser, isLoggedIn } from 'utils/validateToken'
import { constructQouteObject } from 'api/utils/constructRequestObject'
import { SSE_TIMEOUT } from 'constants/sse-waiting-time'
import { getErrorMessageFromErrorCode } from 'helper/errorCode'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import ModalComponent from 'components/common/Modal'
import Spinner from 'components/Loader'
import AddAddressModal from 'views/AddAddressModal'
import EditAddressModal from 'views/EditAddressModal'
import ConfirmationModal from 'views/ConfirmationModal'
import PreviousArrow from 'assets/svg/PreviousArrow'
import NextArrow from 'assets/svg/NextArrow'
import AddIcon from 'assets/svg/AddIcon'
import {
  NameWrapper,
  Title,
  AddressInformationWrapper,
  AddressWrapper,
  BillingText,
  UserInformationWrapper,
  AddressDetailWrapper,
  ButtonWrapper,
  UserName,
  AddressDetail,
  ButtonSection,
  AddressDetailSection,
  DetailWrapper,
  UserAddressWrapper,
  InputFieldWrapper,
  ErrorMessage,
  IconButton,
  ShippingTitleWrapper,
  TitleWrapper,
  SliderWrapper,
  AddWrapper,
} from 'components/checkout/style'
import { generateItemsPayloads, getUserId } from 'helper'

const AddressForm = (props) => {
  const {
    // setInitOrder,
    // setOnSelectResponse,
    setUpdatedCartItems,
    setDeliveryModes,
  } = props
  const user = getUser()
  const dispatch = useContext(ToastContext)
  const [addressList, setAddressList] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteAddressId, setDeleteAddressId] = useState('')
  const [addForm, setAddForm] = useState(false)
  const [editForm, setEditForm] = useState(false)
  const [addressId, setAddressId] = useState(false)
  const [locality, setLocality] = useState([{}])
  const [selectedAddressId, setSelectedAddressId] = useState()
  const [postOffice, setPostOffice] = useState([{}])
  // select
  const localCartItems = localStorage.getItem('cartItems')
  const [cartItems, setCartItems] = useState(JSON.parse(localCartItems))
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [pincodeCoords, setPincodeCoords] = useState([])
  const [loader, setLoader] = useState(false)
  const responseRef = useRef([])
  const eventTimeOutRef = useRef([])
  const { cancellablePromise } = useCancellablePromise()
  const updatedCartItems = useRef([])
  const userId = getUserId()
  const getPinCode = localStorage.getItem('pinCode')
  const currentPincode = localStorage.getItem('currentpinCode')
  updatedCartItems
  useEffect(() => {
    setCartItems(JSON.parse(localCartItems))
    updatedCartItems.current = JSON.parse(localCartItems)
  }, [])

  const { mutateAsync } = usePost()

  const { data: deliveryAddress, refetch: fetchDeliveryAddress } = useGet(
    'delivery_address',
    `/clientApis/v1/delivery_address`,
  )
  const { refetch: fetchUseInfo, data: userInfo } = useGet('userProfile', `/clientApis/getUserProfile`)
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(ADD_ADDRESS_VALIDATION),
  })

  const { pincode, city } = watch()

  useEffect(() => {
    fetchDeliveryAddress()
    fetchUseInfo()
  }, [])

  useEffect(() => {
    setAddressList(deliveryAddress)
  }, [deliveryAddress])

  const singleAddress = () => {
    try {
      {
        addressList?.map((item) => {
          const { id } = item
          setDeliveryModes([{}])
          AddCookie('delivery_address', JSON.stringify(item))
          if (cartItems?.length > 0) {
            setSelectedAddressId(id)
            let c = cartItems?.map((item) => {
              return item.item
            })

            const request_object = constructQouteObject(c)
            getQuote(request_object, item.address.areaCode, item.gps)
            getProviderIds(request_object)
          }
        })
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    if (addressList && addressList.length === 1) singleAddress()
  }, [addressList])

  const getAddressDetails = async () => {
    try {
      setLoader(true)

      const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
      const { data: pincodeCoordinatesData, status } = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${pincode}&format=json&limit=1&countrycodes=IN`,
      )

      if (status === 200) {
        setPincodeCoords([pincodeCoordinatesData[0]?.lat, pincodeCoordinatesData[0]?.lon])
      }

      if (response.data[0]?.Status === 'Error') {
        setLoader(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'Invalid Pincode',
          },
        })
        setValue('locality', '')
        setValue('state', '')
        setValue('city', '')
        setValue('country', '')
      } else if (response.data && response.data[0] && response.data[0].PostOffice) {
        const { State, Block, Country, District } = response.data[0].PostOffice[0]
        const postOffices = response.data[0].PostOffice
        setPostOffice(postOffices)
        const nameList = postOffices.map((postOffice) => postOffice.Name)
        setLocality(nameList)
        setValue('locality', nameList[0])
        setValue('state', State)
        setValue('city', Block === 'NA' ? District : Block)
        setValue('country', Country)
        setLoader(false)
      }
    } catch (err) {
      return err
    }
  }

  const handleLocalityChange = (selectedLocality) => {
    const selectedPostOffice = postOffice.find((postOffice) => postOffice.Name === selectedLocality)

    if (selectedPostOffice) {
      const { State, Block, District, Name, Country } = selectedPostOffice

      setValue('state', State)
      setValue('city', Block === 'NA' ? District : Block)
      setValue('locality', Name)
      setValue('country', Country)
    }
  }

  useEffect(() => {
    if (pincode?.length === 6) {
      getAddressDetails()
    }
  }, [pincode])

  const localities = []

  for (let i = 0; i < locality.length; i++) {
    const localityObject = {
      value: locality[i],
    }
    localities.push(localityObject)
  }

  const onSubmit = async (address) => {
    const latitude = parseFloat(pincodeCoords[0]).toFixed(6).trim()
    const longitude = parseFloat(pincodeCoords[1]).toFixed(6).trim()

    const gps = `${latitude},${longitude}`

    try {
      const payload = {
        descriptor: {
          name: user?.name ? user?.name : address?.name,
          email: user.email ? user?.email : address?.email,
          images: [user?.photoURL],
          phone: address.phone,
          // phone: address.phone.slice(2, address.phone.length),
        },
        address: {
          name: address?.name,
          email: address?.email,
          areaCode: address?.pincode,
          city: address?.city,
          country: address?.country,
          state: address?.state,
          building: address?.building.trim(),
          locality: address.locality.trim(),
          street: address.locality.trim(),
          tag: 'Home', // TODO
          lat: parseFloat(pincodeCoords[0]).toFixed(6),
          lng: parseFloat(pincodeCoords[1]).toFixed(6),
        },

        gps: gps,
      }

      const data = await mutateAsync({
        url: '/clientApis/v1/delivery_address',
        payload: payload,
      })
      if (data) {
        reset()
      }

      AddCookie('delivery_address', JSON.stringify(data))
      fetchDeliveryAddress()
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  const handleEditAddress = (id, address, descriptor) => {
    setEditForm(true)
    setAddressId({ id, address, user, descriptor })
  }

  const handleDelete = (e, id) => {
    setDeleteModal(true)
    setDeleteAddressId(id)
    e.preventDefault()
    e.stopPropagation()
  }

  const handleAddAddress = () => {
    setAddForm(true)
  }
  // const handleInit = (isSelected, id, item) => {
  //   if (!isSelected) {
  //     selectAddress(id)
  //     setInitOrder(item?._id)
  //     AddCookie('delivery_address', JSON.stringify(item))
  //   }
  // }
  // const selectAddress = (id) => {
  //   setSelectedAddressId(id)
  // }

  const getProviderIds = (request_object) => {
    let providers = []
    request_object?.forEach((cartItem) => {
      cartItem.forEach((ele) => {
        providers.push(ele?.provider.local_id)
      })
    })

    const ids = [...new Set(providers)]
    AddCookie('providerIds', ids)
    return ids
  }

  const getQuote = async (items, areaCode, gps) => {
    responseRef.current = []

    try {
      setCheckoutLoading(true)
      const multiSelleSelectPayload = items.map((ele) => {
        const data = generateItemsPayloads(ele)

        return {
          context: {
            domain: data?.domain,
            city: areaCode ? areaCode : getPinCode ? getPinCode : currentPincode,
          },
          message: {
            cart: {
              items: data?.itemsPayloads,
            },
            fulfillments: [
              {
                end: {
                  location: {
                    gps: gps,
                    address: {
                      area_code: areaCode,
                    },
                  },
                },
              },
            ],
          },
          userId: userId,
        }
      })

      // const cartOrdersInfoMap = new Map()
      // multiSelleSelectPayload.forEach((ele) => {
      //   const provider_id = ele?.message?.cart?.items[0]?.provider?.id
      //   if (provider_id) {
      //     cartOrdersInfoMap.set(provider_id, {
      //       transaction_id: ele?.context?.transaction_id,
      //     })
      //   }
      // })

      // AddCookie('orders-cart-info', JSON.stringify(Array.from(cartOrdersInfoMap.entries())))

      const data = await cancellablePromise(postCheckoutCall('/clientApis/v2/select', multiSelleSelectPayload))

      //Error handling workflow eg, NACK
      // const isNACK = data.find((item) => item.error && item?.message?.ack?.status === 'NACK')
      const isNACK = data.find((item) => item?.message?.ack?.status === 'NACK')

      if (isNACK) {
        setCheckoutLoading(false)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'Seller is not available to accept the order.',
          },
        })
      } else {
        // fetch through events
        onFetchQuote(
          data?.map((txn) => {
            const { context } = txn
            return context?.message_id
          }),
          items?.length,
        )
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.warning,
          message: 'Seller is not accessible at the moment.',
        },
      })
      setCheckoutLoading(false)
    }
  }

  function onFetchQuote(message_id, itemLength) {
    eventTimeOutRef.current = []

    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }
    if (message_id) {
      message_id.forEach((id) => {
        let es = new window.EventSourcePolyfill(
          `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
          header,
        )

        es.addEventListener('on_select', (e) => {
          try {
            const { messageId } = JSON.parse(e.data)
            onGetQuote(messageId, itemLength)
            // Close the event connection as soon as the response is received

            es.close()

            const eventTimeout = eventTimeOutRef.current.find((item) => item.eventSource === es)

            if (eventTimeout) {
              clearTimeout(eventTimeout.timer)

              eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
            }
          } catch (error) {
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.warning,
                message: 'Seller is not accepting orders.',
              },
            })
            setCheckoutLoading(false)
          }
        })

        es.addEventListener('error', (error) => {
          if (error) {
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.warning,
                message: 'Seller is not accessible at the moment.',
              },
            })
            setCheckoutLoading(false)
          }
        })

        const timer = setTimeout(() => {
          eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
            eventSource.close()
            clearTimeout(timer)
          })
          if (responseRef.current.length < itemLength) {
            setCheckoutLoading(false)
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.warning,
                message: 'Seller is not accessible at the moment.',
              },
            })
            history.replace('/')
            return
          }

          let c = cartItems?.map((item) => {
            return item.item
          })

          const request_object = constructQouteObject(c)

          if (responseRef.current.length !== request_object.length) {
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message: 'Cannot fetch details for some product those products will be ignored!',
              },
            })
          }
        }, SSE_TIMEOUT)

        eventTimeOutRef.current = [
          ...eventTimeOutRef.current,
          {
            eventSource: es,
            timer,
          },
        ]
      })
    }
  }

  const onGetQuote = async (message_id, itemLength) => {
    try {
      setCheckoutLoading(true)
      const data = await cancellablePromise(getSelectCall(`/clientApis/v2/on_select?messageIds=${message_id}`))
      // const transaction_id = data?.map((txn) => {
      //   const { context } = txn
      //   return context?.transaction_id
      // })

      responseRef.current = [...responseRef.current, data[0]]
      if (data[0]?.error) {
        // Close the loader
        setCheckoutLoading(false)
        const errMessage = getErrorMessageFromErrorCode(data[0]?.error?.code)
        // Display the error message in the toast notification
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: errMessage, // Use the error message from the API response
          },
        })

        // Return from the function or take any other necessary action
        return
      }

      data[0]?.message.quote.items.forEach((item) => {
        const findItemIndexFromCart = updatedCartItems.current.findIndex((prod) => prod.item.product.id === item.id)
        if (findItemIndexFromCart > -1) {
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillment_id = item.fulfillment_id
          updatedCartItems.current[findItemIndexFromCart].item.product.fulfillments = data[0].message.quote.fulfillments
        }
      })

      // setOnSelectResponse((prev) => [...prev, data[0]])
      setDeliveryModes((prev) => [...prev, data[0]?.message?.quote?.fulfillments])
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems.current))
      localStorage.setItem('updatedCartItems', JSON.stringify(responseRef.current))
      const transactionData = responseRef.current.map((item) => {
        const ItemObj = {
          productId: item.message.quote.items.map((item) => item.id),
          providerId: item?.message?.quote?.provider?.id,
          transaction_id: item?.context?.transaction_id,
        }
        return ItemObj
      })
      localStorage.setItem('transactionData', JSON.stringify(transactionData))
      setUpdatedCartItems(responseRef.current)
      if (itemLength === responseRef.current.length) setCheckoutLoading(false)
    } catch (err) {
      setCheckoutLoading(false)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err.message,
        },
      })
    }
  }

  const sliderSettings = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
    nextArrow:
      addressList?.length < 4 ? null : (
        <IconButton>
          <NextArrow />
        </IconButton>
      ),
    prevArrow:
      addressList?.length < 4 ? null : (
        <IconButton>
          <PreviousArrow />
        </IconButton>
      ),
  }

  return (
    <AddressDetailWrapper>
      <BillingText>Billing Information</BillingText>
      {isLoggedIn() && (
        <UserInformationWrapper>
          {user?.phone ? (
            <NameWrapper>
              <Title>Phone Number</Title>
              <TextField required fullWidth type="text" disabled value={userInfo?.data?.phone || user.phone || ''} />
            </NameWrapper>
          ) : (
            <>
              <NameWrapper>
                <Title>Username</Title>
                <TextField
                  required
                  fullWidth
                  type="text"
                  disabled
                  value={userInfo?.data?.userName || user.name || ''}
                />
              </NameWrapper>
              <NameWrapper>
                <Title>Email</Title>
                <TextField required fullWidth type="text" disabled value={userInfo?.data?.email || user.email || ''} />
              </NameWrapper>
            </>
          )}
          <NameWrapper>
            <Title>Payment Type</Title>
            <TextField required fullWidth type="text" disabled value="Prepaid" />
          </NameWrapper>
        </UserInformationWrapper>
      )}

      {!addressList || addressList?.length === 0 || addressList?.every((item) => !item.address) ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddressInformationWrapper>
            {loader && <Spinner />}
            <AddressWrapper>
              <NameWrapper>
                <Title>
                  Name<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        id="name"
                        placeholder="Enter Name"
                        {...field}
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          field.onChange(value)
                        }}
                      />
                    )}
                  />
                  <ErrorMessage>{errors?.name?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
              <NameWrapper>
                <Title>
                  Email<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={user?.email}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        id="name"
                        placeholder="Enter Email"
                        style={{ width: '100%' }}
                        {...field}
                        onChange={(value) => {
                          field.onChange(value)
                        }}
                      />
                    )}
                  />
                  <ErrorMessage>{errors?.email?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
              <NameWrapper>
                <Title>
                  Phone<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        countryCodeEditable={false}
                        country="in"
                        value={value}
                        onChange={onChange}
                        className="phone-input"
                        disableDropdown
                        placeholder="Enter Phone Number"
                        inputStyle={{ boxShadow: 'none' }}
                        inputProps={{
                          name: 'phone',
                          autoFocus: false,
                          focus: false,
                        }}
                      />
                    )}
                  />
                  <ErrorMessage>{errors?.phone?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
              <NameWrapper>
                <Title>
                  Building<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="building"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        type="text"
                        id="building"
                        placeholder="Enter Building"
                        {...field}
                        onChange={(value) => {
                          field.onChange(value)
                        }}
                      />
                    )}
                  />
                  <ErrorMessage>{errors?.building?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>

              <NameWrapper>
                <Title>
                  Pincode<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="pincode"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField type="text" id="pincode" {...field} placeholder="Enter Pincode" />
                    )}
                  />
                  <ErrorMessage>{errors?.pincode?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
              <NameWrapper>
                <Title>
                  Locality<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="locality"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        type="text"
                        id="locality"
                        placeholder="Enter Locality"
                        {...field}
                        className="locality-wrapper"
                        disabled={city ? false : true}
                        onChange={(e) => handleLocalityChange(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              height: '200px',
                              overflowY: 'scroll',
                            },
                          },
                        }}
                      >
                        {localities?.map((locality, index) => (
                          <MenuItem key={index} value={locality?.value}>
                            {locality?.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <ErrorMessage>{errors?.locality?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
              <NameWrapper>
                <Title>
                  City<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField type="text" id="city" {...field} placeholder="Enter City" disabled />
                    )}
                  />
                  <ErrorMessage>{errors?.city?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
              <NameWrapper>
                <Title>
                  Region/State<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField type="text" id="state" {...field} placeholder="Enter Region/State" disabled />
                    )}
                  />
                  <ErrorMessage>{errors?.state?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
              <NameWrapper>
                <Title>
                  Country<span className="asterik">*</span>
                </Title>
                <InputFieldWrapper>
                  <Controller
                    name="country"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField type="text" id="country" {...field} placeholder="Enter Country" disabled />
                    )}
                  />
                  <ErrorMessage>{errors?.country?.message}</ErrorMessage>
                </InputFieldWrapper>
              </NameWrapper>
            </AddressWrapper>
            <ButtonWrapper>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </ButtonWrapper>
          </AddressInformationWrapper>
        </form>
      ) : (
        addressList?.length && (
          <UserAddressWrapper>
            <RadioGroup className="address-wrapper">
              <ShippingTitleWrapper>
                <TitleWrapper>
                  <BillingText>Shipping Address </BillingText>
                  {addressList &&
                  addressList?.length !== 0 &&
                  !addressList?.some((address) => address?.address === null) ? (
                    <AddWrapper color="primary" variant="outline" type="submit" onClick={handleAddAddress}>
                      <AddIcon />
                    </AddWrapper>
                  ) : (
                    ''
                  )}
                </TitleWrapper>
              </ShippingTitleWrapper>
              <SliderWrapper>
                <Slider {...sliderSettings}>
                  {addressList?.map((item, index) => {
                    const { id, address, descriptor } = item
                    const isSelected = addressList?.length === 1 || selectedAddressId === id
                    if (!address) {
                      return null
                    }

                    return (
                      <AddressDetailSection key={index}>
                        <div>
                          <DetailWrapper>
                            <UserName>{address?.name}</UserName>
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={isSelected}
                                  style={{ width: '20px', height: '20px' }}
                                  onClick={() => {
                                    setDeliveryModes([{}])
                                    AddCookie('delivery_address', JSON.stringify(item))
                                    if (cartItems?.length > 0) {
                                      setSelectedAddressId(id)
                                      let c = cartItems?.map((item) => {
                                        return item.item
                                      })
                                      const request_object = constructQouteObject(c)
                                      getQuote(request_object, item.address.areaCode, item.gps)
                                      getProviderIds(request_object)
                                    }
                                  }}
                                />
                              }
                            />
                          </DetailWrapper>
                          <AddressDetail>{`${address?.building}, ${address?.locality}, ${address?.state}`}</AddressDetail>
                          <AddressDetail>{address?.areaCode}</AddressDetail>
                        </div>
                        <ButtonSection>
                          <Button
                            variant="outlined"
                            className="edit-button"
                            onClick={() => {
                              handleEditAddress(id, address, descriptor)
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={(e) => {
                              handleDelete(e, id)
                            }}
                          >
                            Delete
                          </Button>
                        </ButtonSection>
                      </AddressDetailSection>
                    )
                  })}
                </Slider>
              </SliderWrapper>
            </RadioGroup>
          </UserAddressWrapper>
        )
      )}
      {deleteModal && (
        <ModalComponent open={deleteModal} onClose={() => setDeleteModal(false)}>
          <ConfirmationModal
            onClose={() => setDeleteModal(false)}
            id={deleteAddressId}
            fetchDeliveryAddress={fetchDeliveryAddress}
            title={'Are you sure you want to delete the address?'}
            type={'address'}
          />
        </ModalComponent>
      )}

      {checkoutLoading && <Spinner />}
      {addForm && (
        <ModalComponent open={addForm} title="Add New Address" onClose={() => setAddForm(false)}>
          <AddAddressModal onClose={() => setAddForm(false)} />
        </ModalComponent>
      )}
      {editForm && (
        <ModalComponent open={editForm} onClose={() => setEditForm(false)} title="Edit Your Address">
          <EditAddressModal onClose={() => setEditForm(false)} addressDetails={addressId} />
        </ModalComponent>
      )}
    </AddressDetailWrapper>
  )
}

export default AddressForm
