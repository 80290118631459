import React, { useContext, useState } from 'react'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
// import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'
import Input from '../../components/shared/input/input'
import { toast_actions, toast_types } from '../../components/shared/toast/utils/toast'
import { postLoginCall } from '../../api/axios'
import { AddCookie, getValueFromCookie } from '../../utils/cookies'
import { ToastContext } from '../../context/toastContext'
import Google_Logo from '../../assets/images/google.png'
import OtpInput from '../../components/otpInput'
import OtpBackIcon from '../../assets/svg/OtpBackIcon'
import CloseIcon from 'assets/svg/CloseIcon'
import { getOrCreateDeviceId, getUserId } from 'helper'

import {
  Wrapper,
  OtpLogoContainer,
  LogoContainer,
  CrossIconWrapper,
  Title,
  FormWrapper,
  OrText,
  ButtonContainer,
  GoogleText,
  InputWrapper,
  ButtonText,
} from '../../styles/auth/Login'
import {
  LoginContainer,
  OtpLoginTextContainer,
  OtpLoginTitle,
  LoginSubTitle,
  LoginFormWrapper,
  OtpFormWrapper,
  OtpWrapper,
  OtpContainer,
  ResendText,
} from '../../styles/otpScreen'
import { v4 as uuidv4 } from 'uuid'
import Logo from '../../assets/images/BharathamLogo.png'
import { Button as NewButton } from '@mui/material'
import Button from '../../components/shared/button/button'
import usePost from 'hooks/usePost'
import { LoginContext } from 'context/loginContext'

const LoginModal = ({ onClose, onLoginSuccess, buyNow, setOrderModal }) => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  const { mutateAsync } = usePost()
  const [phone, setPhone] = useState()
  const [signInUsingGoogleloading, setSignInUsingGoogleLoading] = useState(false)
  const [signInUsingEmailAndPasswordloading] = useState(false)
  const dispatch = useContext(ToastContext)
  const [otp, setOtp] = useState('')
  const [loginData, setLoginData] = useState(false)
  const [resetOtp, setResetOtp] = useState(false)
  const { login } = useContext(LoginContext)
  const [inlineError, setInlineError] = useState({
    phone_error: '',
    otp_error: '',
  })

  const userId = getUserId()
  // use this function to check the phone validation
  function checkPhone() {
    if (!phone) {
      setInlineError((inlineError) => ({
        ...inlineError,
        phone_error: 'Phone number cannot be empty',
      }))
      return false
    }

    const phoneRegex = /^[0-9]{10}$/
    if (!phoneRegex.test(phone)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        phone_error: 'Enter a valid 10-digit phone number',
      }))
      return false
    }

    return true
  }

  const handleNew = async () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Login with mobile number',
    })

    let user_Id
    let userEmail
    const userCookie = getValueFromCookie('user')
    if (userCookie) {
      const user = JSON.parse(userCookie)
      user_Id = user.id
      userEmail = user.email
    }

    // const customCartId = localStorage.getItem('custom_cart_id')
    // if (!customCartId) {
    //   const randomID = await generateUniqueBrowserId()
    //   localStorage.setItem('custom_cart_id', randomID)
    // }

    const deviceId = await getOrCreateDeviceId()

    const payload = {
      userId: user_Id || userId || '',
      // cart_key: customCartId || '',
      // wishlist_key: customWishlistId || '',
      email: userEmail || '',
      deviceId: deviceId || '',
    }
    try {
      await mutateAsync({
        url: '/clientApis/userProfile',
        payload: payload,
      })
      login()
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  function handleLoginWithGoogle() {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Login with google',
    })
    setSignInUsingGoogleLoading(true)
    signInWithPopup(auth, provider)
      .then((result) => {
        handleRedirect(result.user)
        handleNew()
      })
      .catch((error) => {
        if (error.code !== 'auth/popup-closed-by-user') {
          const errorMessage = error.message
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: errorMessage,
            },
          })
        }
      })
      .finally(() => setSignInUsingGoogleLoading(false))
  }

  let formattedPhone = phone && phone.startsWith('+91') ? phone : `+91${phone}`

  const handleLoginWithPhoneNumber = async () => {
    if (!checkPhone()) {
      return
    }

    const payload = {
      phone: formattedPhone,
    }
    try {
      const data = await postLoginCall(`clientApis/signup`, payload)
      if (data?.success === true) {
        setLoginData(true)
      }

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: data?.message,
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error.message,
        },
      })
    }
  }

  // use this function to check the otp validation
  function checkOtp() {
    if (otp?.length !== 4) {
      setInlineError((inlineError) => ({
        ...inlineError,
        otp_error: 'Please enter a 4-digit OTP',
      }))
      return false
    } else {
      setInlineError((inlineError) => ({
        ...inlineError,
        otp_error: '',
      }))
    }

    return true
  }

  const handleOtpLogin = async () => {
    if (!checkOtp()) {
      return
    }

    const deviceId = await getOrCreateDeviceId()
    // const getCarts = localStorage.getItem('custom_cart_id')
    const payload = {
      phone: formattedPhone,
      otp: otp,
      deviceId: deviceId,
    }
    if (otp?.length === 4) {
      try {
        const data = await postLoginCall(`/clientApis/verifyOTP`, payload)

        if (data?.type === 'success') {
          AddCookie('userId', data?.data?.userId)
          AddCookie('token', data?.data?.token)
          AddCookie('phone', JSON.stringify({ phone: formattedPhone }))
          login()
          onClose()
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.success,
              message: data?.message,
            },
          })
          if (!buyNow) {
            setTimeout(() => {
              onLoginSuccess()
            }, 1000)
          } else {
            setOrderModal(true)
          }
        }
      } catch (error) {
        setResetOtp(true)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: error.message,
          },
        })
      }
    }
  }

  function handleRedirect(user) {
    const { displayName, email, photoURL, accessToken, uid, refreshToken } = user
    AddCookie('token', accessToken)
    AddCookie('refreshToken', refreshToken)
    localStorage.setItem('transaction_id', uuidv4())
    localStorage.setItem('token', accessToken)
    AddCookie('user', JSON.stringify({ name: displayName, id: uid, email, photoURL }))
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: toast_types.success,
        message: 'Logged In Successfully!',
      },
    })

    if (!buyNow) {
      setTimeout(() => {
        onLoginSuccess()
      }, 1000)
    } else {
      setOrderModal(true)
    }

    onClose()
  }

  const getFullOtp = (otp) => {
    if (otp?.length === 4) setOtp(otp)
  }

  const handlePhoneInput = (event) => {
    const inputValue = event.target?.value.replace(/\D/g, '') // Remove non-digit characters
    setPhone(inputValue)

    setInlineError((inlineError) => ({
      ...inlineError,
      phone_error: '',
    }))
  }

  const resendOtp = async () => {
    setResetOtp(true)
    if (!checkPhone()) {
      return
    }

    const payload = {
      phone: formattedPhone,
    }
    try {
      const data = await postLoginCall(`clientApis/resendOtp`, payload)
      if (data?.success === true) {
        setLoginData(true)
      }

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: data?.message,
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error.message,
        },
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOtpLogin() // Trigger OTP login when Enter is pressed
    }
  }

  return (
    <Wrapper isOtpPage={loginData}>
      {loginData ? (
        <OtpLogoContainer>
          <div onClick={() => setLoginData(false)}>
            <OtpBackIcon />
          </div>
          {/* <Link to="/"> */}
          <img src={Logo} alt="Bharatham" />
          {/* </Link> */}
        </OtpLogoContainer>
      ) : (
        <LogoContainer>
          {/* <Link to="/"> */}
          <img src={Logo} alt="Bharatham" />
          {/* </Link> */}
          <CrossIconWrapper>
            <CloseIcon onClick={onClose} />
          </CrossIconWrapper>
        </LogoContainer>
      )}
      {!loginData ? (
        <FormWrapper>
          <form onSubmit={handleLoginWithPhoneNumber}>
            <Title>Sign In and Let&apos;s Explore!</Title>
            <InputWrapper>
              <Input
                id="phone"
                name="phone"
                type="number"
                placeholder="Enter Your Phone Number"
                label_name="Phone Number"
                autoComplete="off"
                has_error={inlineError.phone_error}
                onChange={(event) => {
                  handlePhoneInput(event)
                }}
                onBlur={checkPhone}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault() // Prevent form submission
                    handleLoginWithPhoneNumber() // Trigger OTP screen
                  }
                }}
                required
              />
              {inlineError.phone_error && <span>{inlineError.phone_error}</span>}
            </InputWrapper>
            <ButtonContainer>
              <NewButton className="login_button" variant="outlined" onClick={handleLoginWithPhoneNumber}>
                Next
              </NewButton>
              <OrText>Or</OrText>
              <Button
                className="google_button"
                isloading={signInUsingGoogleloading ? 1 : 0}
                disabled={signInUsingGoogleloading || signInUsingEmailAndPasswordloading}
                button_text={
                  <GoogleText style={{ display: 'flex', gap: '10px' }}>
                    <img src={Google_Logo} alt="logo" />
                    <ButtonText>Login with google</ButtonText>
                  </GoogleText>
                }
                onClick={handleLoginWithGoogle}
              />
            </ButtonContainer>
          </form>
        </FormWrapper>
      ) : (
        <FormWrapper>
          <LoginContainer>
            <OtpLoginTextContainer>
              <OtpLoginTitle>Enter OTP</OtpLoginTitle>
              <LoginSubTitle>Please enter the OTP sent on your phone.</LoginSubTitle>
            </OtpLoginTextContainer>
            <LoginFormWrapper>
              <OtpFormWrapper onSubmit={handleOtpLogin}>
                <OtpContainer>
                  <OtpWrapper>
                    <OtpInput
                      length={4}
                      getFullOtp={getFullOtp}
                      name={'otpp'}
                      resetOtp={resetOtp}
                      setResetOtp={setResetOtp}
                      onKeyDown={handleKeyDown}
                    />
                  </OtpWrapper>
                  {inlineError.otp_error && <span>{inlineError.otp_error}</span>}
                </OtpContainer>

                <div className="buttonSection">
                  <NewButton variant="contained" onClick={handleOtpLogin}>
                    Login
                  </NewButton>
                </div>
                <ResendText onClick={resendOtp}>
                  Didn&apos;t get the OTP? <span>Resend</span>
                </ResendText>
              </OtpFormWrapper>
            </LoginFormWrapper>
          </LoginContainer>
        </FormWrapper>
      )}
    </Wrapper>
  )
}

export default LoginModal
