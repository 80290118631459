import React, { useState, useEffect, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {
  CartSummaryTitle,
  NameWrapper,
  Title,
  AddressInformationWrapper,
  AddressWrapper,
  ButtonWrapper,
  ErrorMessage,
  InputFieldWrapper,
} from 'styles/orderModal'
import usePost from '../../../hooks/usePost'
import useGet from '../../../hooks/useGet'
import { getUser } from '../../../utils/validateToken'
import { AddCookie } from '../../../utils/cookies'
import { toast_actions, toast_types } from '../../../components/shared/toast/utils/toast'
import { ToastContext } from '../../../context/toastContext'
import { ADD_ADDRESS_VALIDATION } from 'utils/Validation/addressForm-validation'

const NewAddressForm = ({ setOpenForm }) => {
  const user = getUser()
  const dispatch = useContext(ToastContext)
  const [locality, setLocality] = useState([{}])
  const [pincodeCoords, setPincodeCoords] = useState([])
  const [postOffice, setPostOffice] = useState([{}])
  const { mutateAsync } = usePost()
  const { refetch: fetchDeliveryAddress } = useGet('delivery_address', `/clientApis/v1/delivery_address`)
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(ADD_ADDRESS_VALIDATION),
  })
  const { pincode, city } = watch()

  const getAddressDetails = async () => {
    try {
      const response = await axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
      const { data: pincodeCoordinatesData, status } = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${pincode}&format=json&limit=1&countrycodes=IN`,
      )

      if (status === 200) {
        setPincodeCoords([pincodeCoordinatesData[0]?.lat, pincodeCoordinatesData[0]?.lon])
      }

      if (response.data[0]?.Status === 'Error') {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'Invalid Pincode',
          },
        })
        setValue('locality', '')
        setValue('state', '')
        setValue('city', '')
        setValue('country', '')
      } else if (response.data && response.data[0] && response.data[0].PostOffice) {
        const { State, Block, Country, District } = response.data[0].PostOffice[0]

        const postOffices = response.data[0].PostOffice
        setPostOffice(postOffices)
        const nameList = postOffices.map((postOffice) => postOffice.Name)

        setLocality(nameList)
        setValue('locality', nameList[0])
        setValue('state', State)
        setValue('city', Block === 'NA' ? District : Block)
        setValue('country', Country)
        clearErrors('locality')
        clearErrors('state')
        clearErrors('city')
        clearErrors('country')
      }
    } catch (err) {
      return err
    }
  }

  const handleLocalityChange = (selectedLocality) => {
    const selectedPostOffice = postOffice.find((postOffice) => postOffice.Name === selectedLocality)

    if (selectedPostOffice) {
      const { State, Block, District, Name, Country } = selectedPostOffice

      setValue('state', State)
      setValue('city', Block === 'NA' ? District : Block)
      setValue('locality', Name)
      setValue('country', Country)
    }
  }

  useEffect(() => {
    if (pincode && pincode.length === 6) {
      getAddressDetails()
    }
  }, [pincode])

  const localities = []

  for (let i = 0; i < locality.length; i++) {
    const localityObject = {
      value: locality[i],
    }
    localities.push(localityObject)
  }

  const onSubmit = async (address) => {
    const latitude = parseFloat(pincodeCoords[0]).toFixed(6).trim()
    const longitude = parseFloat(pincodeCoords[1]).toFixed(6).trim()
    const gps = `${latitude},${longitude}`
    try {
      const payload = {
        descriptor: {
          name: user?.name ? user?.name : address?.name,
          email: user.email ? user?.email : address?.email,
          images: [user?.photoURL],
          phone: address.phone,
          // phone: address.phone.slice(2, address.phone.length),
        },
        address: {
          name: address.name,
          email: address?.email,
          areaCode: address.pincode,
          city: address.city,
          country: address.country,
          state: address.state,
          building: address.building.trim(),
          locality: address.locality.trim(),
          street: address.locality.trim(),
          tag: 'Home', // TODO
          lat: parseFloat(pincodeCoords[0]).toFixed(6),
          lng: parseFloat(pincodeCoords[1]).toFixed(6),
        },
        gps: gps,
      }

      const data = await mutateAsync({
        url: '/clientApis/v1/delivery_address',
        payload: payload,
      })

      if (data) {
        reset()
        setOpenForm(false)
      }

      AddCookie('delivery_address', JSON.stringify(data))
      fetchDeliveryAddress()
      setOpenForm(false)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    }
  }

  const handleCancel = () => {
    setOpenForm(false)
  }

  return (
    <>
      {/* {!addressList || addressList?.length !== 0 ? ( */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddressInformationWrapper>
          <CartSummaryTitle>New Address</CartSummaryTitle>
          <AddressWrapper>
            <NameWrapper>
              <Title>
                Name<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="name"
                      placeholder="Enter Name"
                      {...field}
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.name?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Email<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={user?.email}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="name"
                      placeholder="Enter Email"
                      style={{ width: '100%' }}
                      {...field}
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.email?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Phone<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      countryCodeEditable={false}
                      country="in"
                      value={value}
                      onChange={onChange}
                      className="phone-input"
                      disableDropdown
                      placeholder="Enter Phone Number"
                      inputStyle={{ boxShadow: 'none' }}
                      inputProps={{
                        name: 'phone',
                        autoFocus: false,
                        focus: false,
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.phone?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Building<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="building"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      type="text"
                      id="building"
                      placeholder="Enter Building"
                      {...field}
                      onChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                  )}
                />
                <ErrorMessage>{errors?.building?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Pincode<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="pincode"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => <TextField type="text" id="pincode" {...field} placeholder="Enter Pincode" />}
                />
                <ErrorMessage>{errors?.pincode?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Locality<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="locality"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      type="text"
                      id="locality"
                      placeholder="Enter Locality"
                      {...field}
                      className="locality-wrapper"
                      disabled={city ? false : true}
                      onChange={(e) => handleLocalityChange(e.target.value)}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            height: '200px',
                            overflowY: 'scroll',
                          },
                        },
                      }}
                    >
                      {localities.map((locality, index) => (
                        <MenuItem key={index} value={locality.value}>
                          {locality.value}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <ErrorMessage>{errors?.locality?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                City<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField type="text" id="city" {...field} placeholder="Enter City" disabled />
                  )}
                />
                <ErrorMessage>{errors?.city?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Region/State<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="state"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField type="text" id="state" {...field} placeholder="Enter Region/State" disabled />
                  )}
                />
                <ErrorMessage>{errors?.state?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
            <NameWrapper>
              <Title>
                Country<span className="asterik">*</span>
              </Title>
              <InputFieldWrapper>
                <Controller
                  name="country"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField type="text" id="country" {...field} placeholder="Enter Country" disabled />
                  )}
                />
                <ErrorMessage>{errors?.country?.message}</ErrorMessage>
              </InputFieldWrapper>
            </NameWrapper>
          </AddressWrapper>
          <ButtonWrapper>
            <Button variant="contained" type="submit">
              Save
            </Button>

            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </ButtonWrapper>
        </AddressInformationWrapper>
      </form>
    </>
  )
}

export default NewAddressForm
