import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AddCookie, getValueFromCookie } from 'utils/cookies'
import { SearchContext } from 'context/searchContext'
import { search_types } from 'constants/searchTypes'
import NoDataFound from 'views/EmptyData'
import ProviderImage from 'assets/images/no_image_found.png'
import {
  ProviderCardWrapper,
  BoxWrapper,
  Wrapper,
  ImageWrapper,
  ProviderName,
  NoDataWrapper,
} from 'styles/ProvidersList'

const ProvidersCard = ({ providers }) => {
  const history = useHistory()
  const locationData = useLocation()
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const { setSearchData, setLocationData } = useContext(SearchContext)
  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: '',
  })

  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  function getLastEnteredValues() {
    const searchProductName = query.get('s')
    let search_context = getValueFromCookie('search_context')
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context))
      setSearch(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setSearchedLocation(search_context.location)
      setSearchData(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setLocationData(() => search_context.location)
    }
  }

  useEffect(() => {
    getLastEnteredValues()
  }, [])

  const handleProviderClick = (id) => {
    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))
    history.push(`/brand?brandId=${id}`)
  }

  return (
    <ProviderCardWrapper>
      {providers && providers.length > 0 ? (
        providers.map((item, index) => {
          return (
            <BoxWrapper key={index}>
              <Wrapper onClick={() => handleProviderClick(item.id)}>
                <ImageWrapper>
                  <img
                    src={item?.descriptor?.symbol ? item?.descriptor?.symbol : ProviderImage}
                    alt="providers-image"
                    onError={(e) => {
                      e.target.src =
                        'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                    }}
                  />
                </ImageWrapper>
              </Wrapper>
              <ProviderName>{item?.descriptor?.name}</ProviderName>
            </BoxWrapper>
          )
        })
      ) : (
        <NoDataWrapper className="empty-state">
          <NoDataFound content={'No Data Found'} />
        </NoDataWrapper>
      )}
    </ProviderCardWrapper>
  )
}

export default ProvidersCard
